import { useEffect, useState } from "react";
// import OutsideClickHandler from "react-outside-click-handler";
import Popup from "./Popup";
import {
  getAllUsers,
  fetchUserByStatus,
  searchUser,
  getAllBatchBUsers,
  DownloadUser,
  getAllWeb3Users,
} from "../../lib/data";

import search from "../../images/svg/search.svg";
import accept from "../../images/svg/accept.svg";
import reject from "../../images/svg/reject.svg";
import arrow from "../../images/svg/arrow-down.svg";
import loader from "../../images/gif/loader-purple.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import paymentMode from '../../images/payment-mode.png'

function Web3applicants({ displayPage }) {
  //   console.log(displayPage);

  const [batchUsers, setBatchBUsers] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formAction, setFormAction] = useState("Send to");
  const [users, setUsers] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [activeButton, setActiveButton] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [selectAll, setSelectAll] = useState(null);
  const [duration, setDuration] = useState("");
  const [selectId, setSelectId] = useState([]);
  // const [checkAll, setCheckAll] = useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthh = months[d.getMonth()];
    setMonth(monthh);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayss = days[d.getDay()];
    setDay(dayss);
    const dates = d.getDate();
    if (dates === 1 || dates === 21 || dates === 31) {
      setDate(`${dates}st`);
    } else if (dates === 2 || dates === 22) {
      setDate(`${dates}nd`);
    } else {
      setDate(`${dates}th`);
    }
  }, [page]);

  useEffect(() => {
    getUsersall()
  }, []);

  const getUsersall = () => {
    getAllWeb3Users(page)
      .then((response) => response.data)
      .then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          setLoading(true);
          setUsers(resp.data.applicants);
          setLoading(false);
          setShowPagination(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        // window.location.reload();
      });
  };

  const openModal = (par1) => {
    setFormAction(par1);
    setShowModal(true);
  };

  const paginate = (type) => {
    setLoading(true);
    if (type === "prev") {
      if (page > 0) {
        setPage(page - 1);
        getUsersall();
      }
    } else {
      if (page < 10) {
        setPage(page + 1);
        getUsersall();
      }
    }
  };

  const userSelected = (mail, id) => {
    //there's an issue with this function but it works for now, something is not right
    // var check = document.getElementById(`check-box${id}`);
    const checkAll = document.getElementById("checkAll");
    var check = document.getElementById(`${id}`);
    // console.log(!check.checked);
    if (!check.checked) {
      // console.log("not checked", check);
      // const newSelectedId = selectId.filter(element => {
      //  return element != id
      // })
      setSelectId(prev => {
        const newSet = new Set([...prev, id]);
        return Array.from(newSet);
      })
      
      // let remove = mail;

      // const selectedUser = selectedUsers.filter(function (item) {
      //   return item !== remove;
      // });
      // setSelectAll(mail);
      // setButtonEnabled(true);
      setDownloadBtn(true);
      setActiveButton(false);
      // console.log(selectId);
    } else {
      // setShowEditor(true);
      // setButtonEnabled(false);
      const newSelectedId = selectId.filter(element => {
        return element != id
       })
      //  console.log(newSelectedId);
       if(newSelectedId.length > 0){
        setDownloadBtn(true);
       } else {
        setDownloadBtn(false);
        checkAll.checked = false;
       }
       setSelectId(newSelectedId)
      setActiveButton(true);
      selectedUsers.push(mail);
      selectId.push(id);
      // setDownloadBtn(true);
      setSelectAll(null);
    }
    // console.log(selectedUsers);
    // console.log(selectId);
  };
  useEffect(() => {
    const checkAll = document.getElementById("checkAll");
    // console.log(checkAll.checked);
    
    const handleCheckAll = (id) => {
      // console.log(!checkAll.checked);
      const checkboxes = document.getElementsByClassName(`checkbox`);
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = checkAll.checked;
        const mail = checkboxes[i].getAttribute("data-mail");
        // const id = checkboxes[i].getAttribute("data-id");
        const id = checkboxes[i].getAttribute("id");
        // console.log(id);
        

        if (checkAll.checked) {
          // selectId.push(id);
          setSelectId(prev => {
            // console.log(prev);
            const newSet = new Set([...prev, id]); 
            return Array.from(newSet);
          })
          // console.log(selectId);
        } else {
          // const index = selectedUsers.indexOf(mail);
          // if (index !== -1) {
          //   setSelectedUsers((prevSelected) =>
          //     prevSelected.filter((item) => item !== mail)
          //   );
          // }
          setSelectId([])
        }
      }

      // setSelectId(selectId);
      setActiveButton(checkAll.checked);
      setDownloadBtn(checkAll.checked);
    };

    checkAll.addEventListener("click", handleCheckAll);

    
  }, [selectedUsers]);
// console.log(selectId);

  const doSearch = (e) => {
    setLoading(true);
    if (e.target.value === "") {
    //   getUsersall();
    //   getBatchB();
    } else {
      searchUser(e.target.value)
        .then((response) => response.data)
        .then((resp) => {
          // console.log(resp)
          if (resp?.data) {
            setUsers(resp.data);
            setLoading(false);
            setShowPagination(false);
          } else {
            toast.error("Search is currently unavailable");
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Something went wrong. Try again!!");
          // window.location.reload();
        });
    }
  };

  const fetchByStatus = (thisStatus) => {
    setLoading(true);

    const response = toast.promise(fetchUserByStatus(thisStatus), {
      pending: `Fetching Data`,
      success: "Data fetched successfully.",
      error: `Unable to fetch applicants ${thisStatus}`,
    });
    response
      .then((data) => {
        setUsers(data.data.data.row);
        setLoading(false);
        setShowPagination(false);
      })
      .catch((err) => console.log(err.response));
  };

  // console.log(selectId);
  
  // const url = `https://grazac-admin-backend-d48022f1cdb6.herokuapp.com/api/v1/admin/download?ids=${selectId}`;
  const url = `https://grazac-academy-admin.onrender.com/api/v1/admin/downloadWeb3?ids=${selectId}`;

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function truncateStringWithExtension(str) {
  const lastDotIndex = str?.lastIndexOf('.');
  
  if (lastDotIndex === -1) {
    return str?.length > 8 ? str?.slice(0, 8) + '...' : str;
  }

  const namePart = str?.slice(0, lastDotIndex);
  const extension = str?.slice(lastDotIndex); 
  return namePart?.length > 8 
    ? namePart?.slice(0, 8) + '...' + extension 
    : str;
}

  return (
    <div className="dashboard-content">
      <Popup
        show={showModal}
        formAction={formAction}
        handleClose={() => {
          setShowModal(false);
          setShowEditor(false);
        }}
        emails={selectedUsers}
      />
      <div>
        <h3>Hello Admin</h3>
        <div className="dashboard-content-top">
          <p>
            {day} {month} {date}, {year}
          </p>
          <div className="dashboard-content-search">
            <input
              type="text"
              placeholder="Search"
              onChange={doSearch}
            />
            <img src={search} alt="search" />
          </div>
        </div>
        <div className="disp-flex">
          <p className="text1">
            Web3 Applicants
          </p>
          <div className="dashboard-content-actions">
            <div className="dashboard-content-actions-drop">
              {/* <button
                onClick={() => setShowEditor(true)}
                className=" dashboard-content-actions-btn"
                style={{
                  backgroundColor: activeButton ? "#773DD3" : "#d6d6d6",
                  color: "#fff", // Set text color to white for better visibility
                }}
                disabled={!activeButton}
              >
                Change Status
              </button> */}
              <div
                className=" dashboard-content-actions-downloadBtn"
                style={{
                  backgroundColor: downloadBtn ? "#773DD3" : "#fff",
                  // color: "white", // Set text color to white for better visibility
                }}
              >
                <a href={url}>Export Details</a>
              </div>

              {/* {showEditor && (
                <div>
                  <div
                    onClick={() => setShowEditor(false)}
                    className="dashboard-content-actions-overlay"
                  ></div>

                  <div className="dashboard-content-actions-dropdownContainer">
                    <h5>Change status of the selected applicant(s) to:</h5>
                    <div
                      className="dashboard-content-actions-flex"
                      onClick={() => openModal("accept")}
                    >
                      <label htmlFor="Admitted">Admitted</label>
                      <div className="radio-item">
                        <input type="radio" id="itemId" />
                        <label htmlFor=""></label>
                      </div>
                    </div>

                    <div
                      className="dashboard-content-actions-flex"
                      onClick={() => openModal("select")}
                    >
                      <label htmlFor="Selected">Selected</label>
                      <div className="radio-item">
                        <input type="radio" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                    <div
                      className="dashboard-content-actions-flex"
                      onClick={() => openModal("reject")}
                    >
                      <label htmlFor="Rejected">Rejected</label>
                      <div className="radio-item">
                        <input type="radio" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            {/* <div
              className="disp-flex dashboard-content-actions-div"
              onClick={() => setShowFilter(true)}
            >
              <p>Filter by: Status</p>
              <img
                src={arrow}
                alt="arrow"
                onClick={() => setShowFilter(true)}
              />
              {showFilter && (
                  <div className="dashboard-content-actions-byStatus">
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Under Review")}
                    >
                      <label htmlFor="under-review">Under Review</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="under-review"
                          name="status"
                          value="under-review"
                        />
                        <label htmlFor="under-review"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Selected")}
                    >
                      <label htmlFor="selected">Selected</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="selected"
                          name="status"
                          value="selected"
                        />
                        <label htmlFor="selected"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Not Submitted")}
                    >
                      <label htmlFor="selected">Not Submitted</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="not-submitted"
                          name="status"
                          value="not-submitted"
                        />
                        <label htmlFor="not-submitted"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Admitted")}
                    >
                      <label htmlFor="admitted">Admitted</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="admitted"
                          name="status"
                          value="admitted"
                        />
                        <label htmlFor="admitted"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Rejected")}
                    >
                      <label htmlFor="rejected">Rejected</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="rejected"
                          name="status"
                          value="rejected"
                        />
                        <label htmlFor="rejected"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => window.location.reload()}
                    >
                      <label htmlFor="all">All</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="all"
                          name="status"
                          value="all"
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </div>
                  </div>
                // </OutsideClickHandler>
              )}
            </div> */}
          </div>
        </div>
        <div className="dashboard-content-table">
          <table>
            <thead>
              <tr style={{background: '#F5F8FF'}}>
                <th>
                  <label className="container">
                    <input type="checkbox" id="checkAll" />
                    <span className="checkmark"></span>
                  </label>
                </th>
                <th style={{color: '#000'}}>Name</th>
                <th style={{color: '#000'}}>Email Address</th>
                <th style={{color: '#000'}}>Socials</th>
                <th style={{color: '#000'}}>Phone Number</th>
                <th style={{color: '#000'}}>Application Fee</th>
                <th style={{color: '#000'}}>Payment Mode</th>
                {/* <th>Duration</th> */}
                <th style={{color: '#000'}}>Date</th>

                {/* <th>Date</th> */}
              </tr>
            </thead>
            <tbody>
              {!loading &&
                users?.map((item, idx) => {
                // testData?.map((item, idx) => {
                  // console.log(item);
                  
                  return (
                  <tr key={idx}>
                    <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          // id={`check-box${item._id}`}
                          id={item._id}
                          className="checkbox"
                        />
                        <span
                          className="checkmark"
                          onClick={() => {
                            userSelected(`${item.email}`, `${item._id}`);
                          }}
                        ></span>
                      </label>
                    </td>

                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                    >
                      {item.email}
                    </td>
                    <td>
                      <div className="socials">
                        <a href={item?.githubProfileLink} target="_blank"><FaGithub /></a>
                        <a href={item?.linkedInLink} target="_blank"><FaLinkedin /></a>
                        <a href={item?.twitterLink} target="_blank"><FaXTwitter /></a>
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                    >
                      {item.phoneNumber}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                      
                    >
                      <span className="fee paid">{item.applicationFee?'paid':''}</span>
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                      
                    >
                      <span className="attachment">{item?.attachment ? <a href={item.attachment} target="_blank"><img src={paymentMode} alt='icon' /> {truncateStringWithExtension(item?.attachmentName)}</a> : item.coupon !== '' ? "coupon-" + item?.coupon: 'Flutterwave'}</span>
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/web3/web3details?user=${item._id}`);
                      }}
                    >
                      {/* <p className="duration"> */}
                        {formatDate(item.createdAt)}
                        {/* {item.date} */}
                      {/* </p> */}
                    </td>
                    {/* <td
                      onClick={() => {
                        navigate(`/dashboard/details?user=${item._id}`);
                      }}
                    >
                      <p
                        className={
                          item.status === "Admitted"
                            ? "admitted"
                            : item.status === "Rejected"
                            ? "rejected"
                            : item.status === "Selected"
                            ? "selected"
                            : item.status === "Under Review"
                            ? "under-review"
                            : "not-submitted"
                        }
                      >
                        {item.status}
                      </p>
                    </td> */}
                  </tr>
                )})}
            </tbody>
          </table>
          {loading && (
            <img
              src={loader}
              alt="loader"
              style={{ width: "100px", margin: "50px auto", display: "block" }}
            />
          )}
          {showPagination && (
            <div className="disp-flex dashboard-content-table-pagination">
              <p>Rows per page: {users.length}</p>
              {/* <p>
                {page * 20 - 19}-{page * 20} of {users.length}
              </p>
              <img
                src={arrow}
                alt="arrow"
                className="prev"
                onClick={() => paginate("prev")}
              />
              <img
                src={arrow}
                alt="arrow"
                className="next"
                onClick={() => paginate("next")}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Web3applicants;
