import APIKit from './apikit';

export const LoginAction = (payload) => {
    console.log(payload)
    return APIKit.post(`api/v1/admin/login`, payload)
}

export const ForgotPasswordAction = (payload) => {
    return APIKit.post(`api/v1/admin/forgetPassword`, payload)
}

export const ResetPasswordAction = (payload) => {
    return APIKit.post(`api/v1/admin/resetPassword`, payload)
}
