import React, { useState } from 'react'
import Sidebar from '../component/dashboard/Sidebar'
import Talentcityapplicants from '../component/dashboard/Talentcityapplicants'
import Talentcommercialdetails from '../component/dashboard/talentcommercialdetails'
import Talentresidentdetails from '../component/dashboard/Talentresidentdetails'
import { ToastContainer } from 'react-toastify'
import { Route, Routes } from 'react-router-dom'

const Talentcity = () => {
    const [tabOption, setTabOption] = useState('interest')
  return (
    <div>
        <ToastContainer closeButton={false} />
            {/* <OutsideClickHandler
                onOutsideClick={() => {
                    closeNavMobile();
                }}
            > */}
                <div className="layout_dashboard-sidebar" id="sidebar">
                    <Sidebar />
                </div>
            {/* </OutsideClickHandler> */}
            <div className="layout_dashboard-content">
                <div className="dashboard-nav">
                    {/* <img src={menu} alt="menu" className="dashboard-nav-menu" onClick={showNavMobile} /> */}
                    <div className="dashboard-nav-user">
                        <div>
                            <h2>AD</h2>
                        </div>
                        <p>Admin</p>
                    </div>
                </div>

                <Routes>
                    <Route path="/" exact element={<Talentcityapplicants  tabOption={tabOption} setTabOption={setTabOption}/>} />
                    <Route path="commercialdetails" element={<Talentcommercialdetails setTabOption={setTabOption}/>} />
                    <Route path="residentialdetails" element={<Talentresidentdetails setTabOption={setTabOption}/>} />
                </Routes>
            </div>
    </div>
  )
}

export default Talentcity