import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Route, Routes } from 'react-router-dom';
import Login from "../component/auth/Login";
import ForgotPassword from "../component/auth/ForgotPassword";
import ResetPassword from "../component/auth/ResetPassword";
import MailSent from '../component/auth/MailSent';

import logo from '../images/svg/academy-logo.svg';
import authImg from '../images/auth.png';


function Auth () {
    return(
        <div className="layout_auth">
            <ToastContainer/>
            <div className="layout_auth-main">
                <div className="auth_container">
                    <div className="auth_container-logo" >
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="auth_container_form">
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/mail-sent" element={<MailSent/>}/>
                            <Route path="/resetPassword" element={<ResetPassword/>}/>
                        </Routes>
                        {/* <Login /> */}
                    </div>
                </div>
            </div>
            <div className="layout_auth-image">
                <LazyLoadImage src={authImg} effect="blur" alt="auth" height="auto" width="100%" />
            </div>
        </div>
    )
}

export default Auth;