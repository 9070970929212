import { useEffect, useState } from "react";
// import OutsideClickHandler from "react-outside-click-handler";
import Popup from "./Popup";
import {
  getAllUsers,
  fetchUserByStatus,
  searchUser,
  getAllBatchBUsers,
  DownloadUser,
} from "../../lib/data";

import search from "../../images/svg/search.svg";
import accept from "../../images/svg/accept.svg";
import reject from "../../images/svg/reject.svg";
import arrow from "../../images/svg/arrow-down.svg";
import loader from "../../images/gif/loader-purple.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

function Applicants({ displayPage }) {
  //   console.log(displayPage);

  const [batchUsers, setBatchBUsers] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formAction, setFormAction] = useState("Send to");
  const [users, setUsers] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [activeButton, setActiveButton] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [selectAll, setSelectAll] = useState(null);
  const [duration, setDuration] = useState("");
  const [selectId, setSelectId] = useState([]);
  // const [checkAll, setCheckAll] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthh = months[d.getMonth()];
    setMonth(monthh);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayss = days[d.getDay()];
    setDay(dayss);
    const dates = d.getDate();
    if (dates === 1 || dates === 21 || dates === 31) {
      setDate(`${dates}st`);
    } else if (dates === 2 || dates === 22) {
      setDate(`${dates}nd`);
    } else {
      setDate(`${dates}th`);
    }

    // // getUsersall()
    // if (displayPage==="batcha"){
    //     getUsersall()
    //     // getBatchB();
    //     setUsers([])
    //     console.log(displayPage)
    // }
    // if (displayPage==="batchb"){
    //     getBatchB();
    //     console.log(displayPage)

    // }

    // getBatchB();
  }, [page]);

  useEffect(() => {
    // getBatchB();
    if (users === []) {
      // console.log(users);
      setLoading(true);
    }
    if (displayPage === "batcha") {
      // getUsersall()
      // getBatchB();
      setUsers([]);
      getUsersall();

      // console.log(displayPage);
    }
    if (displayPage === "batchb") {
      setUsers([]);
      getUsersall();
      // getBatchB();
      // console.log(displayPage);
    }
  }, [displayPage]);

  const getUsersall = () => {
    // fetch users
    getAllUsers(page)
      .then((response) => response.data)
      .then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          setLoading(true);
          setUsers(resp.data.user);
          setLoading(false);
          setShowPagination(true);
          // console.log(resp.data.user);
        }
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
      });
  };

  const getBatchB = () => {
    // fetch users
    getAllBatchBUsers(page)
      .then((response) => response.data)
      .then((resp) => {
        // console.log(resp)
        if (resp.status === 200) {
          setLoading(true);
          setBatchBUsers(resp.data.user);
          setUsers(resp.data.user);

          setLoading(false);
          setShowPagination(true);
          // console.log(resp.data.user);
        }
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
      });
  };

  const openModal = (par1) => {
    setFormAction(par1);
    setShowModal(true);
  };

  const paginate = (type) => {
    setLoading(true);
    if (type === "prev") {
      if (page > 0) {
        setPage(page - 1);
        getUsersall();
        getBatchB();
      }
    } else {
      if (page < 10) {
        setPage(page + 1);
        getUsersall();
        getBatchB();
      }
    }
  };

  // const userSelected = (mail, id) => {
  //   //there's an issue with this function but it works for now, something is not right
  //   // var check = document.getElementById(`check-box${id}`);
  //   const checkAll = document.getElementById("checkAll");
  //   var check = document.getElementById(`${id}`);

  //   if (check.checked) {
  //     setSelectId((prev) => {
  //       const newSet = new Set([...prev, id]);
  //       return Array.from(newSet);
  //     });
  //     // console.log("not checked");
  //     // let remove = mail;

  //     // const selectedUser = selectedUsers.filter(function (item) {
  //     //   return item !== remove;
  //     // });
  //     // setSelectAll(mail);
  //     // setButtonEnabled(true);
  //     setDownloadBtn(true);
  //     setActiveButton(true);
  //     // setDownloadBtn(false);
  //   } else {
  //     const newSelectedId = selectId.filter((element) => {
  //       return element != id;
  //     });
  //     //  console.log(newSelectedId);
  //     if (newSelectedId.length > 0) {
  //       setDownloadBtn(true);
  //       setActiveButton(true);
  //     } else {
  //       setDownloadBtn(false);
  //       setActiveButton(false);

  //       checkAll.checked = false;
  //     }
  //     setSelectId(newSelectedId);
  //     // setShowEditor(true);
  //     // setButtonEnabled(false);
  //     // setActiveButton(true);
  //     selectedUsers.push(mail);
  //     console.log(selectedUsers)
  //     selectId.push(id);
  //     console.log(selectedUsers)
  //     // setDownloadBtn(true);
  //     setSelectAll(null);
  //   }
  //   // console.log(selectedUsers);
  //   // console.log(selectId);
  // };
  const userSelected = (mail, id) => {
    setSelectId((prev) => {
      let updatedSelectId;

      if (prev.includes(id)) {
        // If already selected, remove the ID
        updatedSelectId = prev.filter((selectedId) => selectedId !== id);
      } else {
        // Otherwise, add the ID
        updatedSelectId = [...prev, id];
      }

      // Update related states based on the new selection
      setDownloadBtn(updatedSelectId.length > 0);
      setActiveButton(updatedSelectId.length > 0);

      // If nothing is selected, ensure 'checkAll' is unchecked
      const checkAll = document.getElementById("checkAll");
      if (updatedSelectId.length === 0 && checkAll) {
        checkAll.checked = false;
      }

      return updatedSelectId;
    });

    setSelectedUsers((prev) => {
      let updatedUsers;

      if (prev.includes(mail)) {
        // If already selected, remove the mail
        updatedUsers = prev.filter((selectedMail) => selectedMail !== mail);
      } else {
        // Otherwise, add the mail
        updatedUsers = [...prev, mail];
      }

      return updatedUsers;
    });
    console.log(selectedUsers);
    // Reset select all if no users are selected
    setSelectAll(null);
  };
  useEffect(() => {
    const checkAll = document.getElementById("checkAll");

    const handleCheckAll = (id) => {
      const checkboxes = document.getElementsByClassName(`checkbox`);

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = checkAll.checked;
        const mail = checkboxes[i].getAttribute("data-mail");
        // const id = checkboxes[i].getAttribute("data-id");
        const id = checkboxes[i].getAttribute("id");

        if (checkAll.checked) {
          setSelectId((prev) => {
            // console.log(prev);
            const newSet = new Set([...prev, id]);
            return Array.from(newSet);
          });
          // selectId.push(id);
          // console.log(selectId);
        } else {
          // const index = selectedUsers.indexOf(mail);
          // if (index !== -1) {
          //   setSelectedUsers((prevSelected) =>
          //     prevSelected.filter((item) => item !== mail)
          //   );
          // }
          setSelectId([]);
        }
      }

      // setSelectId(selectId);
      setActiveButton(checkAll.checked);
      setDownloadBtn(checkAll.checked);
    };

    checkAll.addEventListener("click", handleCheckAll);
  }, [selectedUsers]);

  const doSearch = (e) => {
    setLoading(true);
    if (e.target.value === "") {
      getUsersall();
      getBatchB();
    } else {
      searchUser(e.target.value)
        .then((response) => response.data)
        .then((resp) => {
          // console.log(resp)
          if (resp.data) {
            setUsers(resp.data);
            setLoading(false);
            setShowPagination(false);
          } else {
            toast.error("Search is currently unavailable");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong. Try again!!");
          // window.location.reload();
        });
    }
  };

  const fetchByStatus = (thisStatus) => {
    setLoading(true);

    const response = toast.promise(fetchUserByStatus(thisStatus), {
      pending: `Fetching Data`,
      success: "Data fetched successfully.",
      error: `Unable to fetch applicants ${thisStatus}`,
    });
    response
      .then((data) => {
        setShowFilter(false);

        setUsers(data.data.data.row);
        setLoading(false);
        setShowPagination(false);
      })
      .catch((err) => console.log(err.response));
  };

  // const url = `https://grazac-admin-backend-d48022f1cdb6.herokuapp.com/api/v1/admin/download?ids=${selectId}`;
  const url = `https://grazac-academy-admin.onrender.com/api/v1/admin/download?ids=${selectId}`;

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return (
    <div className="dashboard-content">
      <Popup
        show={showModal}
        formAction={formAction}
        handleClose={() => {
          setShowModal(false);
          setShowEditor(false);
        }}
        emails={selectedUsers}
      />
      <div>
        <h3>Hello Admin</h3>
        <div className="dashboard-content-top">
          <p>
            {day} {month} {date}, {year}
          </p>
          <div className="dashboard-content-search">
            <input
              type="text"
              placeholder="Search by Email"
              onChange={doSearch}
            />
            <img src={search} alt="search" />
          </div>
        </div>
        <div className="disp-flex">
          <p className="text1">
            {/* Applicants: {displayPage === "batcha" ? "Batch A" : "Batch B"} */}
            Applicants: Cohort 015
          </p>
          <div className="dashboard-content-actions">
            {/* {showEditor && (
              <div
                className="disp-flex dashboard-content-actions-div"
                onClick={() => openModal("select")}
                
              >
                <img src={accept} alt="search" />
                <p className="text1">Select All</p>
              </div>
            )}
            {showEditor && (
              <div
                className="disp-flex dashboard-content-actions-div"
                onClick={() => openModal("reject")}
              >
                <img src={reject} alt="search" />
                <p className="text1 red-text">Reject All</p>
              </div>
            )} */}
            <div className="dashboard-content-actions-drop">
              <button
                onClick={() => setShowEditor(true)}
                className=" dashboard-content-actions-btn"
                style={{
                  backgroundColor: activeButton ? "#773DD3" : "#d6d6d6",
                  color: "#fff", // Set text color to white for better visibility
                }}
                disabled={!activeButton}
              >
                Change Status
              </button>
              <div
                className=" dashboard-content-actions-downloadBtn"
                style={{
                  backgroundColor: downloadBtn ? "#773DD3" : "#fff",
                  // color: "white", // Set text color to white for better visibility
                }}
              >
                <a href={url}>Export Details</a>
              </div>

              {showEditor && (
                <div>
                  <div
                    onClick={() => setShowEditor(false)}
                    className="dashboard-content-actions-overlay"
                  ></div>

                  <div className="dashboard-content-actions-dropdownContainer">
                    {/* <h5>Change status of 24 selected applicants to:</h5> */}
                    <h5>Change status of the selected applicant(s) to:</h5>
                    <div
                      className="dashboard-content-actions-flex"
                      // onClick={() => fetchByStatus("Selected")}
                      onClick={() => openModal("accept")}
                    >
                      <label htmlFor="Admitted">Admitted</label>
                      <div className="radio-item">
                        <input type="radio" id="itemId" />
                        <label htmlFor=""></label>
                      </div>
                    </div>

                    <div
                      className="dashboard-content-actions-flex"
                      // onClick={() => fetchByStatus("Selected")}
                      onClick={() => openModal("select")}
                    >
                      <label htmlFor="Selected">Selected</label>
                      <div className="radio-item">
                        <input type="radio" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                    <div
                      className="dashboard-content-actions-flex"
                      // onClick={() => fetchByStatus("Selected")}
                      onClick={() => openModal("reject")}
                    >
                      <label htmlFor="Rejected">Rejected</label>
                      <div className="radio-item">
                        <input type="radio" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="disp-flex dashboard-content-actions-div"
              onClick={() => setShowFilter(true)}
            >
              <p>Filter by: Status</p>
              <img
                src={arrow}
                alt="arrow"
                onClick={() => setShowFilter(true)}
              />
              {showFilter && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowFilter(false);
                  }}
                >
                  <div className="dashboard-content-actions-byStatus">
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Under Review")}
                    >
                      <label htmlFor="under-review">Under Review</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="under-review"
                          name="status"
                          value="under-review"
                        />
                        <label htmlFor="under-review"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Selected")}
                    >
                      <label htmlFor="selected">Selected</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="selected"
                          name="status"
                          value="selected"
                        />
                        <label htmlFor="selected"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Not Submitted")}
                    >
                      <label htmlFor="selected">Not Submitted</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="not-submitted"
                          name="status"
                          value="not-submitted"
                        />
                        <label htmlFor="not-submitted"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Admitted")}
                    >
                      <label htmlFor="admitted">Admitted</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="admitted"
                          name="status"
                          value="admitted"
                        />
                        <label htmlFor="admitted"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("Rejected")}
                    >
                      <label htmlFor="rejected">Rejected</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="rejected"
                          name="status"
                          value="rejected"
                        />
                        <label htmlFor="rejected"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("paid")}
                    >
                      <label htmlFor="paid">App.Fee(Paid)</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="paid"
                          name="status"
                          value="paid"
                        />
                        <label htmlFor="paid"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => fetchByStatus("notPaid")}
                    >
                      <label htmlFor="notPaid">App.Fee (Not Paid)</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="notPaid"
                          name="status"
                          value="notPaid"
                        />
                        <label htmlFor="notPaid"></label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="status"
                      onClick={() => window.location.reload()}
                    >
                      <label htmlFor="all">All</label>
                      <div className="radio-item">
                        <input
                          type="radio"
                          id="all"
                          name="status"
                          value="all"
                        />
                        <label htmlFor="all"></label>
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-content-table">
          <table>
            <thead>
              <tr>
                <th>
                  <label className="container">
                    <input type="checkbox" id="checkAll" />
                    <span className="checkmark"></span>
                  </label>
                </th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Application Fee</th>

                {/* <th>Duration</th> */}
                <th>Date Registered</th>

                {/* <th>Date</th> */}
              </tr>
            </thead>
            <tbody>
              {!loading &&
                users?.map((item, idx) => {
                  console.log(item);

                  return (
                    <tr key={idx}>
                      <td>
                        <label className="container">
                          <input
                            type="checkbox"
                            // id={`check-box${item._id}`}
                            id={item._id}
                            className="checkbox"
                          />
                          <span
                            className="checkmark"
                            onClick={() => {
                              userSelected(`${item.email}`, `${item._id}`);
                            }}
                          ></span>
                        </label>
                      </td>

                      <td
                        onClick={() => {
                          // window.location = `/details?user=${item.id}`;
                          // window.location = `dashboard/details?user=${item.id}`;
                          navigate(`/dashboard/details?user=${item._id}`);
                          // navigate(`details?user=${item.id}`);
                        }}
                      >
                        {item.firstName} {item.lastName}
                      </td>
                      <td
                        onClick={() => {
                          // window.location = `/details?user=${item.id}`;
                          // window.location = `dashboard/details?user=${item.id}`;
                          navigate(`/dashboard/details?user=${item._id}`);
                          // navigate(`details?user=${item.id}`);
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        onClick={() => {
                          // window.location = `/details?user=${item.id}`;
                          // window.location = `dashboard/details?user=${item.id}`;
                          navigate(`/dashboard/details?user=${item._id}`);
                          // navigate(`details?user=${item.id}`);
                        }}
                      >
                        {item.phoneNumber}
                      </td>
                      <td
                        onClick={() => {
                          // window.location = `/details?user=${item.id}`;
                          // window.location = `dashboard/details?user=${item.id}`;
                          navigate(`/dashboard/details?user=${item._id}`);
                          // navigate(`details?user=${item.id}`);
                        }}
                      >
                        <p
                          className={
                            item.status === "Admitted"
                              ? "admitted"
                              : item.status === "Rejected"
                              ? "rejected"
                              : item.status === "Selected"
                              ? "selected"
                              : item.status === "Under Review"
                              ? "under-review"
                              : "not-submitted"
                          }
                        >
                          {item.status}
                        </p>
                      </td>

                      <td
                        onClick={() => {
                          // window.location = `/details?user=${item.id}`;
                          // window.location = `dashboard/details?user=${item.id}`;
                          navigate(`/dashboard/details?user=${item._id}`);
                          // navigate(`details?user=${item.id}`);
                        }}
                      >
                        <p
                          className={
                            item.applicationFee ? "appPaid" : "appNotPaid"
                          }
                        >
                          {item.applicationFee ? "Paid" : "Not Paid"}
                        </p>
                      </td>
                      {/* <td
                      onClick={() => {
                        window.location = `/details?user=${item._id}`;
                      }}
                    >
                      <p className="duration">
                        {item?.timeElapsed?.days > 0
                          ? item?.timeElapsed?.days
                          : item?.timeElapsed?.hours > 0 &&
                            item?.timeElapsed?.hours < 24
                          ? item?.timeElapsed?.hours
                          : item?.timeElapsed?.minutes > 0 &&
                            item?.timeElapsed?.minutes < 60
                          ? item?.timeElapsed?.minutes
                          : item?.timeElapsed?.seconds}{" "}
                        {item?.timeElapsed?.days > 0
                          ? "day(s)"
                          : item?.timeElapsed?.hours > 0 &&
                            item?.timeElapsed?.hours < 24
                          ? "hours"
                          : item?.timeElapsed?.minutes > 0 &&
                            item?.timeElapsed?.minutes < 60
                          ? "minutes"
                          : "seconds"}
                      </p>
                    </td> */}
                      <td
                        onClick={() => {
                          window.location = `/details?user=${item._id}`;
                        }}
                      >
                        <p className="duration">
                          {formatDate(item.created_at)}
                        </p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && (
            <img
              src={loader}
              alt="loader"
              style={{ width: "100px", margin: "50px auto", display: "block" }}
            />
          )}
          {showPagination && (
            <div className="disp-flex dashboard-content-table-pagination">
              <p>Rows per page: {users.length}</p>
              {/* <p>
                {page * 20 - 19}-{page * 20} of {users.length}
              </p>
              <img
                src={arrow}
                alt="arrow"
                className="prev"
                onClick={() => paginate("prev")}
              />
              <img
                src={arrow}
                alt="arrow"
                className="next"
                onClick={() => paginate("next")}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Applicants;
