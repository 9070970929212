import { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ResetPasswordAction } from '../../lib/auth';

import visible from '../../images/svg/visible.svg';
import notvisible from '../../images/svg/notvisible.svg';
import loader from "../../images/gif/loader.gif";

function Reset () {
    const urlParams = new URLSearchParams(window.location.search);
    const reset_token = urlParams.get('token');
    const [form, setForm] = useState({
        password: "",
        confirmpassword: ""
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const doReset = (e) => {
        e.preventDefault();
        if (!form.password.length || !form.confirmpassword.length) {
            toast.info("Please make sure no field is empty!")
        }else if(form.password != form.confirmpassword){
            toast.error("Passwords do not match!")
        }else if(form.password.length<6 || form.confirmpassword<6){
            toast.error("Passwords should be at least 6 characters!")
        } else{
            var btn = document.getElementById("actionBtn");
            btn.innerHTML = `<img src=${loader} width="20px" />`;
            
            let payload = JSON.stringify({
                token: reset_token,
                password: form.password,
                confirmPassword: form.confirmpassword
            });
            
            ResetPasswordAction(payload)
                .then((response) => response.data)
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        toast.success("Password reset successful! Proceed to login.");
                        btn.innerHTML=`Reset Password`;
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                        btn.innerHTML=`Reset Password`;
                    }
                })
                .catch((err) => {
                    console.log(err.response)//modify the errors
                    toast.error("Unable to reset password! Try again");
                //     if(err.response.data.message){
                //         toast.error(err.response.data.message);
                //         btn.innerHTML=`Reset Password`;
                //     } else{
                //         toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                //         btn.innerHTML=`Reset Password`;
                //     }
                });
        }
    }

    const toggleIcon = () => {
        var x = document.getElementById("password");
        var iconContainer = document.getElementById("toggle");
        if (x.type === "password") {
          x.type = "text";
          iconContainer.innerHTML = `<img src=${notvisible} />`
        } else {
          x.type = "password";
          iconContainer.innerHTML = `<img src=${visible} />` ;
        }
    }
    
    const toggleIcon2 = () => {
        var x = document.getElementById("confirmpassword");
        var iconContainer = document.getElementById("confirmtoggle");
        if (x.type === "password") {
            x.type = "text";
            iconContainer.innerHTML = `<img src=${notvisible} />`
        } else {
            x.type = "password";
            iconContainer.innerHTML = `<img src=${visible} />` ;
        }
    }

    return(
        <div>
            <ToastContainer closeButton={false}/>
            <h3>Create New Password</h3>
            <p className="auth_container_form-text">Enter your new password below to continue.</p>
            <form onSubmit={doReset}>
                <div className="auth_container_form-password">
                    <label>Password</label>
                    <input type="password" id="password" placeholder="Password" name="password" onChange={handleChange} />
                    <div id="toggle" onClick={toggleIcon} ><img src={visible} /></div>
                </div>
                <div className="auth_container_form-password">
                    <label>Confirm Password</label>
                    <input type="password" id="confirmpassword" placeholder="Password" name="confirmpassword" onChange={handleChange} />
                    <div id="confirmtoggle" onClick={toggleIcon2} ><img src={visible} /></div>
                </div>
                <button type="submit" id="actionBtn">Reset Password</button>
                <p>Remember your password? <Link to="/auth" className="link-decor"><span>Login</span></Link></p>
            </form>
        </div>
    )
}

export default Reset;