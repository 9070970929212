import { useState, useEffect } from "react";
import { editProject, getOne } from "../../../../lib/hire";
import { ToastContainer, toast } from "react-toastify";

import addForm from "../../../../images/add-form.png";
import deleteForm from "../../../../images/delete-form.png";
import loader from "../../../../images/gif/loader.gif";


function Projects ({userId, next, user, fetched}) {
    const [form1, setForm1] = useState(true);
    const [form2, setForm2] = useState(false);
    const [form3, setForm3] = useState(false);
    const [form4, setForm4] = useState(false);
    const [form5, setForm5] = useState(false);
    const [project1, setProject1] = useState("");
    const [project2, setProject2] = useState("");
    const [project3, setProject3] = useState("");
    const [project4, setProject4] = useState("");
    const [project5, setProject5] = useState("");
    const [fetched1, setFetched1] = useState({});
    const [fetched2, setFetched2] = useState({});
    const [fetched3, setFetched3] = useState({});
    const [fetched4, setFetched4] = useState({});
    const [fetched5, setFetched5] = useState({});
    const [details1, setDetails1] = useState({
        type:"Project",
        role:"",
        title:"",
        description:"",
        project_link:"none",
        startMonth:"",
        endMonth:"",
        startYear:"",
        endYear:""
    })
    const [details2, setDetails2] = useState({
        type:"Project",
        role:"",
        title:"",
        description:"",
        project_link:"none",
        startMonth:"",
        endMonth:"",
        startYear:"",
        endYear:""
    });
    const [details3, setDetails3] = useState({
        type:"Project",
        role:"",
        title:"",
        description:"",
        project_link:"none",
        startMonth:"",
        endMonth:"",
        startYear:"",
        endYear:""
    });
    const [details4, setDetails4] = useState({
        type:"Project",
        role:"",
        title:"",
        description:"",
        project_link:"none",
        startMonth:"",
        endMonth:"",
        startYear:"",
        endYear:""
    });
    const [details5, setDetails5] = useState({
        type:"Project",
        role:"",
        title:"",
        description:"",
        project_link:"none",
        startMonth:"",
        endMonth:"",
        startYear:"",
        endYear:""
    })
    
    useEffect(()=>{
        getOne(userId)
            .then(response => response.data)
            .then(resp=>{
                console.log(resp);
                const { length } = resp.profile.project;
                if (length===1){
                    // setForm1(true);  --- alraedy true
                    setProject1(resp.profile.project[0].type)
                    setFetched1(resp.profile.project[0])
                    setDetails1(resp.profile.project[0])
                }else if (length===2){
                    setForm2(true);
                    setProject1(resp.profile.project[0].type)
                    setProject2(resp.profile.project[1].type)
                    setFetched1(resp.profile.project[0])
                    setDetails1(resp.profile.project[0])
                    setFetched2(resp.profile.project[1])
                    setDetails2(resp.profile.project[1])
                }else if (length===3){
                    setForm2(true);
                    setForm3(true);
                    setProject1(resp.profile.project[0].type)
                    setProject2(resp.profile.project[1].type)
                    setProject3(resp.profile.project[2].type)
                    setFetched1(resp.profile.project[0])
                    setDetails1(resp.profile.project[0])
                    setFetched2(resp.profile.project[1])
                    setDetails2(resp.profile.project[1])
                    setFetched3(resp.profile.project[2])
                    setDetails3(resp.profile.project[2])
                }else if (length===4){
                    setForm2(true);
                    setForm3(true);
                    setForm4(true);
                    setProject1(resp.profile.project[0].type)
                    setProject2(resp.profile.project[1].type)
                    setProject3(resp.profile.project[2].type)
                    setProject4(resp.profile.project[3].type)
                    setFetched1(resp.profile.project[0])
                    setDetails1(resp.profile.project[0])
                    setFetched2(resp.profile.project[1])
                    setDetails2(resp.profile.project[1])
                    setFetched3(resp.profile.project[2])
                    setDetails3(resp.profile.project[2])
                    setFetched4(resp.profile.project[3])
                    setDetails4(resp.profile.project[3])
                } else {
                    setForm2(true);
                    setForm3(true);
                    setForm4(true);
                    setForm5(true);
                    setProject1(resp.profile.project[0].type)
                    setProject2(resp.profile.project[1].type)
                    setProject3(resp.profile.project[2].type)
                    setProject4(resp.profile.project[3].type)
                    setProject5(resp.profile.project[4].type)
                    setFetched1(resp.profile.project[0])
                    setDetails1(resp.profile.project[0])
                    setFetched2(resp.profile.project[1])
                    setDetails2(resp.profile.project[1])
                    setFetched3(resp.profile.project[2])
                    setDetails3(resp.profile.project[2])
                    setFetched4(resp.profile.project[3])
                    setDetails4(resp.profile.project[3])
                    setFetched5(resp.profile.project[4])
                    setDetails5(resp.profile.project[4])
                }
            })
            .catch(err=>{
                console.log(err);
                // window.location.reload();
            })
    },[]);

    // const createNewForm = (name) => {
    //     if(name==="form1"){
    //         setForm2(true)
    //     } else if (name==="form2") {
    //         setForm3(true)
    //     } else if (name==="form3") {
    //         setForm4(true)
    //     } else if (name==="form4") {
    //         setForm5(true)
    //     }
    // }

    // const deleteSomeForm = (name) => {
    //     if(name==="form1"){
    //         setForm1(false)
    //     } else if (name==="form2") {
    //         setForm2(false);
    //     } else if (name==="form3") {
    //         setForm3(false);
    //     } else if (name==="form4") {
    //         setForm4(false);
    //     } else if (name==="form5") {
    //         setForm5(false);
    //     }
    // }
    
    const handleChange1 = (e) => {
        setDetails1({
            ...details1,
            [e.target.name]: e.target.value
        });
    }
    const handleChange2 = (e) => {
        setDetails2({
            ...details2,
            [e.target.name]: e.target.value
        });
    }
    const handleChange3 = (e) => {
        setDetails3({
            ...details3,
            [e.target.name]: e.target.value
        });
    }
    const handleChange4 = (e) => {
        setDetails4({
            ...details4,
            [e.target.name]: e.target.value
        });
    }
    const handleChange5 = (e) => {
        setDetails5({
            ...details5,
            [e.target.name]: e.target.value
        });
    }

    const submitForm = () => {
        const btn = document.getElementById("save");
        btn.innerHTML=`<img src=${loader} width="20px" />`;
        console.log(userId)
        if(details1.role===""){
            // can't be
            alert ("Please fill the form to proceed")
        } else if (details2.role==="") {
            const payload = JSON.stringify({
                info: [details1]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details3.role===""){
            const payload = JSON.stringify({
                info: [details1, details2]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details4.role==="") {
            const payload = JSON.stringify({
                info: [details1, details2, details3]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details5.role==="") {
            const payload = JSON.stringify({
                info: [details1, details2, details3, details4]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else {
            const payload = JSON.stringify({
                info: [details1, details2, details3, details4, details5]
            })
            console.log(payload)
            mainSubmitAction(payload);
        }
    }

    const mainSubmitAction = (payload) => {
        const btn = document.getElementById("save");
        console.log(userId)
        editProject(userId, payload)
            .then(res=>res)
            .then(resp=>{
                console.log(resp);
                toast.success(resp.data.message);
                btn.innerHTML=`Save Changes`;
            })
            .catch((err)=>{
                console.log(err);
                btn.innerHTML=`Save Changes`;
            })
    }

    return(
        <div>
            <ToastContainer closeButton={false} />
            <div className="dashboard-content-form-edu" id="formMain" >
                <h3>Project & Experience</h3>
                <div>
                        <div>
                            {form1 && 
                                <div className="dashboard-content-form-edu-add">
                                    <div className="dashboard-content-form-container">
                                        <label>Type</label><br/>
                                        <select name="type" onChange={handleChange1}>
                                            <option value={fetched1.type}>{fetched1.type}</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>Role</label><br/>
                                        <input type="text" required name="role" defaultValue={fetched1.role} onChange={handleChange1} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project1==="Project" && <label>Project Title</label>}
                                        {!project1==="Experience" && <label>Company name</label>} <br/>
                                        <input type="text" required name="title" defaultValue={fetched1.title}  onChange={handleChange1} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project1==="Project" && <label>Project Description</label>}
                                        {!project1==="Experience" && <label>Job Description</label>}<br/>
                                        <textarea type="text" required name="description" defaultValue={fetched1.description} onChange={handleChange1} />
                                    </div>
                                    {project1==="Project" && 
                                        <div className="dashboard-content-form-container">
                                            <label>Project Link</label><br/>
                                            <input type="text" required name="project_link" defaultValue={fetched1.project_link} onChange={handleChange1} />
                                        </div>
                                    }
                                    <div className="dashboard-content-form-container">
                                        <label>Start Year</label><br/>
                                        <select name="startMonth" onChange={handleChange1} >
                                            <option value={fetched1.startMonth}>{fetched1.startMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="startYear" onChange={handleChange1} >
                                            <option value={fetched1.startYear}>{fetched1.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>End Year</label><br/>
                                        <select name="endMonth" onChange={handleChange1} >
                                            <option value={fetched1.endMonth}>{fetched1.endMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="endYear" onChange={handleChange1} >
                                            <option value={fetched1.startYear}>{fetched1.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-actions">
                                        {/* <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form1")} alt="delete"/>
                                        </div>
                                        {!form2 && <img src={addForm} onClick={()=>createNewForm("form1")} alt="add" className="dashboard-content-form-actions-add" />} */}
                                    </div>
                                </div>
                            }
                            {form2 && 
                                <div className="dashboard-content-form-edu-add">
                                    <div className="dashboard-content-form-container">
                                        <label>Type</label><br/>
                                        <select name="type" onChange={handleChange2}>
                                            <option value={fetched2.type}>{fetched2.type}</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>Role</label><br/>
                                        <input type="text" required name="role" defaultValue={fetched2.role} onChange={handleChange2} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project2==="Project" && <label>Project Title</label>}
                                        {!project2==="Experience" && <label>Company name</label>} <br/>
                                        <input type="text" required name="title" defaultValue={fetched2.title}  onChange={handleChange2} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project2==="Project" && <label>Project Description</label>}
                                        {!project2==="Experience" && <label>Job Description</label>}<br/>
                                        <textarea type="text" required name="description" defaultValue={fetched2.description} onChange={handleChange2} />
                                    </div>
                                    {project2==="Project" && 
                                        <div className="dashboard-content-form-container">
                                            <label>Project Link</label><br/>
                                            <input type="text" required name="project_link" defaultValue={fetched2.project_link} onChange={handleChange2} />
                                        </div>
                                    }
                                    <div className="dashboard-content-form-container">
                                        <label>Start Year</label><br/>
                                        <select name="startMonth" onChange={handleChange2} >
                                            <option value={fetched2.startMonth}>{fetched2.startMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="startYear" onChange={handleChange2} >
                                            <option value={fetched2.startYear}>{fetched2.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>End Year</label><br/>
                                        <select name="endMonth" onChange={handleChange2} >
                                            <option value={fetched2.endMonth}>{fetched2.endMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="endYear" onChange={handleChange2} >
                                            <option value={fetched2.startYear}>{fetched2.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-actions">
                                        {/* {form1 &&
                                            <div className="dashboard-content-form-actions-delete">
                                                <img src={deleteForm} onClick={()=>deleteSomeForm("form2")} alt="delete"/>
                                            </div>
                                        }
                                        {!form3 &&<img src={addForm} onClick={()=>createNewForm("form2")} alt="add" className="dashboard-content-form-actions-add" />} */}
                                    </div>
                                </div>
                            }
                            {form3 && 
                                <div className="dashboard-content-form-edu-add">
                                    <div className="dashboard-content-form-container">
                                        <label>Type</label><br/>
                                        <select name="type" onChange={handleChange3}>
                                            <option value={fetched3.type}>{fetched3.type}</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>Role</label><br/>
                                        <input type="text" required name="role" defaultValue={fetched3.role} onChange={handleChange3} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project3==="Project" && <label>Project Title</label>}
                                        {!project3==="Experience" && <label>Company name</label>} <br/>
                                        <input type="text" required name="title" defaultValue={fetched3.title}  onChange={handleChange3} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project3==="Project" && <label>Project Description</label>}
                                        {!project3==="Experience" && <label>Job Description</label>}<br/>
                                        <textarea type="text" required name="description" defaultValue={fetched3.description} onChange={handleChange3} />
                                    </div>
                                    {project3==="Project" && 
                                        <div className="dashboard-content-form-container">
                                            <label>Project Link</label><br/>
                                            <input type="text" required name="project_link" defaultValue={fetched3.project_link} onChange={handleChange3} />
                                        </div>
                                    }
                                    <div className="dashboard-content-form-container">
                                        <label>Start Year</label><br/>
                                        <select name="startMonth" onChange={handleChange3} >
                                            <option value={fetched3.startMonth}>{fetched3.startMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="startYear" onChange={handleChange3} >
                                            <option value={fetched3.startYear}>{fetched3.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>End Year</label><br/>
                                        <select name="endMonth" onChange={handleChange3} >
                                            <option value={fetched3.endMonth}>{fetched3.endMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="endYear" onChange={handleChange3} >
                                            <option value={fetched3.startYear}>{fetched3.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    {/* <div className="dashboard-content-form-actions">
                                        {form2 &&
                                            <div className="dashboard-content-form-actions-delete">
                                                <img src={deleteForm} onClick={()=>deleteSomeForm("form3")} alt="delete"/>
                                            </div>
                                        }
                                        {!form4 &&<img src={addForm} onClick={()=>createNewForm("form3")} alt="add" className="dashboard-content-form-actions-add" />}
                                    </div> */}
                                </div>
                            }
                            {form4 && 
                                <div className="dashboard-content-form-edu-add">
                                    <div className="dashboard-content-form-container">
                                        <label>Type</label><br/>
                                        <select name="type" onChange={handleChange4}>
                                            <option value={fetched4.type}>{fetched4.type}</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>Role</label><br/>
                                        <input type="text" required name="role" defaultValue={fetched4.role} onChange={handleChange4} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project4==="Project" && <label>Project Title</label>}
                                        {!project4==="Experience" && <label>Company name</label>} <br/>
                                        <input type="text" required name="title" defaultValue={fetched4.title}  onChange={handleChange4} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project4==="Project" && <label>Project Description</label>}
                                        {!project4==="Experience" && <label>Job Description</label>}<br/>
                                        <textarea type="text" required name="description" defaultValue={fetched4.description} onChange={handleChange4} />
                                    </div>
                                    {project4==="Project" && 
                                        <div className="dashboard-content-form-container">
                                            <label>Project Link</label><br/>
                                            <input type="text" required name="project_link" defaultValue={fetched4.project_link} onChange={handleChange4} />
                                        </div>
                                    }
                                    <div className="dashboard-content-form-container">
                                        <label>Start Year</label><br/>
                                        <select name="startMonth" onChange={handleChange4} >
                                            <option value={fetched4.startMonth}>{fetched4.startMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="startYear" onChange={handleChange4} >
                                            <option value={fetched4.startYear}>{fetched4.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>End Year</label><br/>
                                        <select name="endMonth" onChange={handleChange4} >
                                            <option value={fetched4.endMonth}>{fetched4.endMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="endYear" onChange={handleChange4} >
                                            <option value={fetched4.startYear}>{fetched4.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-actions">
                                        {/* {form3 &&
                                            <div className="dashboard-content-form-actions-delete">
                                                <img src={deleteForm} onClick={()=>deleteSomeForm("form4")} alt="delete"/>
                                            </div>
                                        }
                                        {!form5 &&<img src={addForm} onClick={()=>createNewForm("form4")} alt="add" className="dashboard-content-form-actions-add" />} */}
                                    </div>
                                </div>
                            }
                            {form5 && 
                                <div className="dashboard-content-form-edu-add">
                                    <div className="dashboard-content-form-container">
                                        <label>Type</label><br/>
                                        <select name="type" onChange={handleChange5}>
                                            <option value={fetched5.type}>{fetched5.type}</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>Role</label><br/>
                                        <input type="text" required name="role" defaultValue={fetched5.role} onChange={handleChange5} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project5==="Project" && <label>Project Title</label>}
                                        {!project5==="Experience" && <label>Company name</label>} <br/>
                                        <input type="text" required name="title" defaultValue={fetched5.title}  onChange={handleChange5} />
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        {project5==="Project" && <label>Project Description</label>}
                                        {!project5==="Experience" && <label>Job Description</label>}<br/>
                                        <textarea type="text" required name="description" defaultValue={fetched5.description} onChange={handleChange5} />
                                    </div>
                                    {project5==="Project" && 
                                        <div className="dashboard-content-form-container">
                                            <label>Project Link</label><br/>
                                            <input type="text" required name="project_link" defaultValue={fetched5.project_link} onChange={handleChange5} />
                                        </div>
                                    }
                                    <div className="dashboard-content-form-container">
                                        <label>Start Year</label><br/>
                                        <select name="startMonth" onChange={handleChange5} >
                                            <option value={fetched5.startMonth}>{fetched5.startMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="startYear" onChange={handleChange5} >
                                            <option value={fetched5.startYear}>{fetched5.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-container">
                                        <label>End Year</label><br/>
                                        <select name="endMonth" onChange={handleChange5} >
                                            <option value={fetched5.endMonth}>{fetched5.endMonth}</option>
                                            <option value="Jan">January</option>
                                            <option value="Feb">February</option>
                                            <option value="Mar">March</option>
                                            <option value="Apr">April</option>
                                            <option value="May">May</option>
                                            <option value="Jun">June</option>
                                            <option value="Jul">July</option>
                                            <option value="Aug">August</option>
                                            <option value="Sep">September</option>
                                            <option value="Oct">October</option>
                                            <option value="Nov">November</option>
                                            <option value="Dec">December</option>
                                        </select>
                                        <select name="endYear" onChange={handleChange5} >
                                            <option value={fetched5.startYear}>{fetched5.startYear}</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                            <option value="2016">2016</option>
                                            <option value="2015">2015</option>
                                            <option value="2014">2014</option>
                                            <option value="2013">2013</option>
                                            <option value="2012">2012</option>
                                            <option value="2011">2011</option>
                                            <option value="2010">2010</option>
                                            <option value="2009">2009</option>
                                            <option value="2008">2008</option>
                                            <option value="2007">2007</option>
                                            <option value="2006">2006</option>
                                            <option value="2005">2005</option>
                                            <option value="2004">2004</option>
                                            <option value="2003">2003</option>
                                        </select>
                                    </div>
                                    <div className="dashboard-content-form-actions">
                                        {/* <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form5")} alt="delete"/>
                                        </div> */}
                                        {/* <img src={addForm} onClick={()=>createNewForm("form5")} alt="add" className="dashboard-content-form-actions-add" /> */}
                                    </div>
                                </div>
                            }
                            <div className="dashboard-content-form-edu-submit" >
                                <button onClick={submitForm} id="save">Save Changes</button>
                            </div>
                        </div>
                </div>
            </div>
            
        </div>
    )
}


export default Projects;