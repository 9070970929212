import { useEffect, useState } from "react";
// import OutsideClickHandler from 'react-outside-click-handler';
import { getAll, changeStatus, filterByStatus, searchTalent, deleteTalent } from "../../lib/hire";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { toast, ToastContainer } from "react-toastify";

import search from "../../images/svg/search.svg";
import action from "../../images/svg/action.svg";
import arrow from "../../images/svg/arrow-down.svg"; 
import loader from "../../images/gif/loader-purple.gif";


function Hire () {
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [date, setDate] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formAction, setFormAction] = useState("Send to");
    const [users, setUsers] = useState([]);
    const [showEditor, setShowEditor] = useState(false);
    const [loading, setLoading] = useState(true);
    let [page, setPage] = useState(1);
    let [selectedUsers] = useState([]);
    const [showPagination, setShowPagination] = useState(true);

    useEffect(()=>{
        const d = new Date();
        setYear(d.getFullYear());
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthh = months[d.getMonth()];
        setMonth(monthh);
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let dayss = days[d.getDay()];
        setDay(dayss);
        const dates = d.getDate();
        if(dates===1 || dates===21 || dates===31) {
            setDate(`${dates}st`)
        } else if (dates===2 || dates===22) {
            setDate(`${dates}nd`)
        } else{
            setDate(`${dates}th`)
        }

        getAllTalents()
    }, [page]);

    const getAllTalents = () => {
        // fetch users
        getAll(page)
            .then(response => response.data)
            .then(resp=>{
                console.log(resp)
                if(resp.status===200){
                    setUsers(resp.data.users);
                    setLoading(false)
                    setShowPagination(true);
                    // console.log(resp.data);
                }
            })
            .catch(err=>{
                console.log(err);
                window.location.reload();
            })
    }

    const paginate = (type) => {
        setLoading(true)
        if(type==="prev"){
            if(page>0){
                setPage(page-1);
                getAllTalents();
            }
        } else {
            if(page<10){
                setPage(page+1);
                getAllTalents();
            }
        }
    }

    const doSearch = (e) => {
        setLoading(true);
        if(e.target.value===""){
            getAllTalents()
        } else {
            searchTalent(e.target.value)
                .then(response => response.data)
                .then(resp=>{
                    console.log(resp)
                    if(resp.data){
                        setUsers(resp.data);
                        setLoading(false);
                        setShowPagination(false);
                    } else {
                        toast.error("Search is currently unavailable");
                    }
                })
                .catch(err=>{
                    console.log(err);
                    toast.error("Something went wrong. Try again!!");
                    // window.location.reload();
                })
        }
    }

    const fetchByStatus = (stat) => {
        setLoading(true);
        filterByStatus(stat)
            .then(resp=>resp)
            .then(data => {
                setUsers(data.data.data.row);
                setLoading(false);
                setShowPagination(false);
            })
            .catch(err => console.log(err.response));

    }

    const changeTalentStatus = (tal_id, tal_email, tal_status) => {
        console.log(tal_id,tal_status);
        const statusChangeFunction = () => {
            const payload = JSON.stringify({
                email: tal_email,
                status: tal_status==="Hired"?"Unhired":"Hired"
            })
             return changeStatus(payload)
        };
        
        const response = toast.promise(
            statusChangeFunction,
            {
                pending: `Changing user status to ${tal_status==="Hired"?"unhired":"hired"}`,
                success: 'Status changed successfully.',
                error: 'Unable to change user status, please try again!'
            }
        );
        response
            .then(data => {
                console.log(data)
                getAllTalents()
            })
            .catch(err => console.log(err.response));
    }

    const deleteData = (tal_id) => {
        const deleteFunction = () => {
             return deleteTalent(tal_id)
        };
        
        const response = toast.promise(
            deleteFunction,
            {
                pending: `Please wait, deleting user...`,
                success: 'User deleted successfully.',
                error: 'Unable to delete user, please try again!'
            }
        );
        response
            .then(data => {
                console.log(data)
                getAllTalents()
            })
            .catch(err => console.log(err.response));
    }

    const handleAction = (id) => {
        var nodeChild = document.getElementById(id);
        nodeChild.classList.toggle('hide');
    }


    return (
        <div className="dashboard-content">
            <ToastContainer/>
            <div>
                <h3>Hello Admin</h3>
                <div className="dashboard-content-top">
                    <p>{day} {month} {date}, {year}</p>
                    <div className="dashboard-content-search">
                        <input type="text" placeholder="Search by Email" onChange={doSearch} />
                        <img src={search} alt="search" />
                    </div>
                    <button className="dashboard-content-top-btn1" onClick={()=>{window.location=`/hireDetails?user=none`}}>Add New</button>
                </div>
                <div className="disp-flex">
                    <p className="text1">Tech Talents</p>
                    <div className="dashboard-content-actions">
                        <div className="disp-flex dashboard-content-actions-div">
                            <p>Filter by: Status</p>
                            <img src={arrow} alt="arrow" onClick={()=>setShowFilter(true)} />
                            {showFilter &&
                                // <OutsideClickHandler
                                //     onOutsideClick={() => {
                                //         setShowFilter(false);
                                //     }}
                                // >
                                    <div className="dashboard-content-actions-byStatus">
                                        <div className="status" onClick={()=>fetchByStatus('Hired')}>
                                            <label htmlFor="hired">Hired</label>
                                            <div className="radio-item">
                                                <input type="radio" id="hired" name="status" value="hired" />
                                                <label htmlFor="hired"></label>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="status" onClick={()=>fetchByStatus('Unhired')}>
                                            <label htmlFor="unhired">Unhired</label>
                                            <div className="radio-item">
                                                <input type="radio" id="unhired" name="status" value="unhired" />
                                                <label htmlFor="unhired"></label>
                                            </div>
                                        </div>
                                    </div>
                                // </OutsideClickHandler>
                            }
                        </div>
                    </div>
                </div>
                <div className="dashboard-content-table">
                    <table>
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>Name</th>
                                <th>Profession</th>
                                <th>Location</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                (users?.map((item,idx)=>(
                                    <tr key={idx}>
                                        {/* <td>
                                            <label className="container">
                                                <input type="checkbox" id={`check-box${item.id}`} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td> */}
                                        <td onClick={()=>{window.location=`/hireDetails?user=${item.id}`}}>{item.firstName} {item.lastName}</td>
                                        <td onClick={()=>{window.location=`/hireDetails?user=${item.id}`}}>{item.profession}</td>
                                        <td onClick={()=>{window.location=`/hireDetails?user=${item.id}`}}>{item.location}</td>
                                        <td onClick={()=>{window.location=`/hireDetails?user=${item.id}`}}>
                                            <p className={item.status==="Hired" ? "admitted" :
                                                            item.status==="Unhired" ? "under-review" :
                                                            "not-submitted"}
                                            >
                                                {item.status}
                                            </p>
                                        </td>
                                        <td>
                                            {/* <Toggle
                                                defaultChecked={item?.status==="Hired"?true:false}
                                                icons={false}
                                                className='toggle-button'
                                                onChange={()=>changeTalentStatus(item.id, item.email, item.status)} 
                                            /> */}
                                            <img src={action} alt="action" onClick={()=>{handleAction(item.id)}} />
                                            <div id={item.id} className="dashboard-content-table-action hide">
                                                <p onClick={()=>{window.location=`/editDetails?user=${item.id}`}}>Edit Data</p>
                                                <p onClick={()=>changeTalentStatus(item.id, item.email, item.status)} >Change Status</p>
                                                <p onClick={()=>deleteData(item.id)} >Delete Data</p>
                                            </div>
                                        </td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>
                    {loading && <img src={loader} alt="loader" style={{width:"100px", margin:"50px auto", display:"block"}} /> }
                    {showPagination &&
                        <div className="disp-flex dashboard-content-table-pagination">
                            <p>Rows per page: {users.length}</p>
                            <p>{page*20-19}-{page*20} of {users.length}</p>
                            <img src={arrow} alt="arrow" className="prev" onClick={()=>paginate("prev")} />
                            <img src={arrow} alt="arrow" className="next" onClick={()=>paginate("next")} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Hire;