import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Web3 from "./pages/Web3";
import Talentcity from "./pages/Talentcity";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./scss/main.css";


function App() {
  
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/*" exact element={<Dashboard/>} />
    //     <Route path="auth/*" exact element={<Auth/>} />
    //   </Routes>
    // </BrowserRouter>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Auth />} />
        <Route path="/dashboard/*" element={<Dashboard />} /> 
        <Route path="/web3/*" element={<Web3 />} /> 
        <Route path="/talent-city/*" element={<Talentcity />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
