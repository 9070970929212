import APIKit from './apikit';

export const getAll = (page) => {
    return APIKit.get(`/hire/fetch?page=${page}`)
}

export const getOne = (id) => {
    return APIKit.get(`/hire/details/${id}`)
}

export const changeStatus = (payload) => {
    return APIKit.patch(`/hire/updateStatus`, payload)
}

export const filterByStatus = (status) => {
    return APIKit.get(`/hire/filter?status=${status}`)
}

export const searchTalent = (email) => {
    return APIKit.get(`/hire/search?email=${email}`)
}

export const addBasicData = (payload) => {
    return APIKit.post(`/hire/basic`, payload)
}

export const addEducationData = (userId, payload) => {
    return APIKit.post(`/hire/education/${userId}`, payload)
}

export const addAwardData = (userId, payload) => {
    return APIKit.post(`/hire/awards/${userId}`, payload)
}

export const addProjectData = (userId, payload) => {
    return APIKit.post(`/hire/project/${userId}`, payload)
}

export const deleteTalent = (userId) => {
    return APIKit.delete(`/hire/delete?id=${userId}`)
}

export const editBasicData = (userId, payload) => {
    return APIKit.post(`/hire/edit/${userId}`, payload)
}

export const editEducation = (userId, payload) => {
    return APIKit.post(`/hire/editEducation/${userId}`, payload)
}

export const editAward = (userId, payload) => {
    return APIKit.post(`/hire/editAward/${userId}`, payload)
}

export const editProject = (userId, payload) => {
    return APIKit.post(`/hire/editProject/${userId}`, payload)
}