import { useState, useEffect } from "react";
import Sidebar from "../component/dashboard/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
// import OutsideClickHandler from 'react-outside-click-handler';
import Applicants from '../component/dashboard/Applicants';
import Details from '../component/dashboard/Details';
import HireMain from '../component/hire';
import HireDetails from '../component/hire/AddNew';
import EditDetails from '../component/hire/Edit';
import { Route, Routes } from 'react-router-dom';
import menu from "../images/svg/menu.svg";


function Dashboard () {

    useEffect(() => {
        try {
            let details = sessionStorage.getItem('admin_details');
            if(!details){
                // window.location = '/auth';
                window.location = '/';
            }
        } catch (err) {
            //redirect to login
            // window.location = '/auth';
            window.location = '/';
        }
    }, []);

    const showNavMobile = () => {
        document.getElementById("sidebar").style.left = "0px"
    }
    const closeNavMobile = () => {
        if(window.innerWidth < 769){
            document.getElementById("sidebar").style.left = "-210px";
        }
    }

    return(
        <div>
            <ToastContainer closeButton={false} />
            {/* <OutsideClickHandler
                onOutsideClick={() => {
                    closeNavMobile();
                }}
            > */}
                <div className="layout_dashboard-sidebar" id="sidebar">
                    <Sidebar />
                </div>
            {/* </OutsideClickHandler> */}
            <div className="layout_dashboard-content">
                <div className="dashboard-nav">
                    <img src={menu} alt="menu" className="dashboard-nav-menu" onClick={showNavMobile} />
                    <div className="dashboard-nav-user">
                        <div>
                            <h2>AD</h2>
                        </div>
                        <p>Admin</p>
                    </div>
                </div>

                <Routes>
                    <Route path="/" exact element={<Applicants  displayPage={"batchb"}/>} />
                    <Route path="/batcha" exact element={<Applicants  displayPage={"batcha"}/>} />
                    <Route path="/batchb" exact element={<Applicants displayPage={"batchb"}/>} />
                    <Route path="details" element={<Details/>} />
                    <Route path="/hire" element={<HireMain/>} />
                    <Route path="/hireDetails" element={<HireDetails/>} />
                    <Route path="/editDetails" element={<EditDetails/>} />
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;