import { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { LoginAction } from '../../lib/auth';

import visible from '../../images/svg/visible.svg';
import notvisible from '../../images/svg/notvisible.svg';
import loader from "../../images/gif/loader.gif";
import { useNavigate } from 'react-router-dom';

// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import logo from '../../images/svg/academy-logo.svg';
// import authImg from '../../images/auth.png';

function Login () {
    const navigate = useNavigate()
    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const doLogin = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`<img src=${loader} width="20px" />`;
        const { email, password } = form;
        
        let payload = JSON.stringify({
            email: email,
            password: password
        });
        // console.log(payload)
        LoginAction(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    toast.success(`${res.message}! Redirecting to dashboard...`);

                    sessionStorage.setItem(
                        "admin_details",
                        JSON.stringify({
                        email: res.data.payload.email,
                        token: res.data.token
                        })
                    ); 
                
                    btn.innerHTML=`Sign in`;
                    // setTimeout( function (){window.location = '/';}, 2500);
                    // setTimeout( function (){window.location = 'dashboard';}, 2500);
                    setTimeout(() => navigate('/dashboard'), 2500);
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                // console.log(err);
                // console.log(err.response);
                // console.log(err);
                if(err.response.data.message){
                    toast.error(err.response.data.message);
                    btn.innerHTML=`Sign in`;
                } else{
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    btn.innerHTML=`Sign in`;
                }
            });
    }

    const toggleIcon = () => {
        var x = document.getElementById("password");
        var iconContainer = document.getElementById("toggle");
        if (x.type === "password") {
          x.type = "text";
          iconContainer.innerHTML = `<img src=${notvisible} />`
        } else {
          x.type = "password";
          iconContainer.innerHTML = `<img src=${visible} />` ;
        }
    }

    return(
        <div>
            <ToastContainer closeButton={false}/>
            <h3>Login to your account</h3>
            <p className="auth_container_form-text">Enter your email address and password to continue.</p>
            <form onSubmit={doLogin}>
                <div>
                    <label>Email Address</label>
                    <input type="email" required placeholder="Email Address" name="email" onChange={handleChange} />
                </div>
                <div className="auth_container_form-password">
                    <label>Password</label>
                    <input type="password" required id="password" placeholder="Password" name="password" onChange={handleChange} />
                    <div id="toggle" onClick={toggleIcon} ><img src={visible} alt= "icon" /></div>
                </div>
                <button type="submit" id="actionBtn">Sign in</button>
                <p><Link to="/forgot-password" className="link-decor"><span>Forgot your password?</span></Link></p>
                {/* <p><Link to="/" className="link-decor"><span>Forgot your password?</span></Link></p> */}
            </form>
        </div>
    )
}

export default Login;