import { useState, useEffect } from "react";
import { editAward, getOne } from "../../../../lib/hire";
import { ToastContainer, toast } from "react-toastify";
// import addForm from "../../../images/add-form.png";
// import deleteForm from "../../../images/delete-form.png";
import loader from "../../../../images/gif/loader.gif";

function Awards ({userId, next, user}) {
    const [form1, setForm1] = useState(true);
    const [form2, setForm2] = useState(false);
    const [form3, setForm3] = useState(false);
    const [form4, setForm4] = useState(false);
    const [form5, setForm5] = useState(false);
    const [award1, setAward1] = useState({});
    const [award2, setAward2] = useState({});
    const [award3, setAward3] = useState({});
    const [award4, setAward4] = useState({});
    const [award5, setAward5] = useState({});
    const [details1, setDetails1] = useState({
        title:"",
        awarder:"",
        certificate_lfnk:"",
        monthAwarded:"",
        yearAwarded:""
    })
    const [details2, setDetails2] = useState({
        title:"",
        awarder:"",
        certificate_link:"",
        monthAwarded:"",
        yearAwarded:""
    });
    const [details3, setDetails3] = useState({
        title:"",
        awarder:"",
        certificate_link:"",
        monthAwarded:"",
        yearAwarded:""
    });
    const [details4, setDetails4] = useState({
        title:"",
        awarder:"",
        certificate_link:"",
        monthAwarded:"",
        yearAwarded:""
    });
    const [details5, setDetails5] = useState({
        title:"",
        awarder:"",
        certificate_link:"",
        monthAwarded:"",
        yearAwarded:""
    })

    // const createNewForm = (name) => {
    //     if(name==="form1"){
    //         setForm2(true)
    //     } else if (name==="form2") {
    //         setForm3(true)
    //     } else if (name==="form3") {
    //         setForm4(true)
    //     } else if (name==="form4") {
    //         setForm5(true)
    //     }
    // }

    // const deleteSomeForm = (name) => {
    //     if(name==="form1"){
    //         setForm1(false)
    //     } else if (name==="form2") {
    //         setForm2(false);
    //     } else if (name==="form3") {
    //         setForm3(false);
    //     } else if (name==="form4") {
    //         setForm4(false);
    //     } else if (name==="form5") {
    //         setForm5(false);
    //     }
    // }
    
    const handleChange1 = (e) => {
        setDetails1({
            ...details1,
            [e.target.name]: e.target.value
        });
    }
    const handleChange2 = (e) => {
        setDetails2({
            ...details2,
            [e.target.name]: e.target.value
        });
    }
    const handleChange3 = (e) => {
        setDetails3({
            ...details3,
            [e.target.name]: e.target.value
        });
    }
    const handleChange4 = (e) => {
        setDetails4({
            ...details4,
            [e.target.name]: e.target.value
        });
    }
    const handleChange5 = (e) => {
        setDetails5({
            ...details5,
            [e.target.name]: e.target.value
        });
    }

    useEffect(()=>{
        getOne(userId)
            .then(response => response.data)
            .then(resp=>{
                console.log(resp);
                const { length } = resp.profile.award;
                console.log(length)
                if (length===1){
                    // setForm1(true);  --- alraedy true
                    setAward1(resp.profile.award[0])
                    setDetails1(resp.profile.award[0])
                }else if (length===2){
                    setForm2(true);
                    setAward1(resp.profile.award[0])
                    setDetails1(resp.profile.award[0])
                    setAward2(resp.profile.award[1])
                    setDetails2(resp.profile.award[1])
                }else if (length===3){
                    setForm2(true);
                    setForm3(true);
                    setAward1(resp.profile.award[0])
                    setDetails1(resp.profile.award[0])
                    setAward2(resp.profile.award[1])
                    setDetails2(resp.profile.award[1])
                    setAward3(resp.profile.award[2])
                    setDetails3(resp.profile.award[2])
                }else if (length===4){
                    setForm2(true);
                    setForm3(true);
                    setForm4(true);
                    setAward1(resp.profile.award[0])
                    setDetails1(resp.profile.award[0])
                    setAward2(resp.profile.award[1])
                    setDetails2(resp.profile.award[1])
                    setAward3(resp.profile.award[2])
                    setDetails3(resp.profile.award[2])
                    setAward4(resp.profile.award[3])
                    setDetails4(resp.profile.award[3])
                } else {
                    setForm2(true);
                    setForm3(true);
                    setForm4(true);
                    setForm5(true);
                    setAward1(resp.profile.award[0])
                    setDetails1(resp.profile.award[0])
                    setAward2(resp.profile.award[1])
                    setDetails2(resp.profile.award[1])
                    setAward3(resp.profile.award[2])
                    setDetails3(resp.profile.award[2])
                    setAward4(resp.profile.award[3])
                    setDetails4(resp.profile.award[3])
                    setAward5(resp.profile.award[4])
                    setDetails5(resp.profile.award[4])
                }
            })
            .catch(err=>{
                console.log(err);
                // window.location.reload();
            })
    },[]);

    const submitForm = () => {
        const btn = document.getElementById("save");
        btn.innerHTML=`<img src=${loader} width="20px" />`;
        console.log(userId)
        if(details1.title===""){
            // can't be
            alert ("Please fill the form to proceed")
        } else if (details2.title==="") {
            const payload = JSON.stringify({
                info: [details1]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details3.title===""){
            const payload = JSON.stringify({
                info: [details1, details2]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details4.title==="") {
            const payload = JSON.stringify({
                info: [details1, details2, details3]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else if (details5.title==="") {
            const payload = JSON.stringify({
                info: [details1, details2, details3, details4]
            })
            console.log(payload)
            mainSubmitAction(payload);
        } else {
            const payload = JSON.stringify({
                info: [details1, details2, details3, details4, details5]
            })
            console.log(payload)
            mainSubmitAction(payload);
        }
    }

    const mainSubmitAction = (payload) => {
        const btn = document.getElementById("save");
        console.log(userId)
        editAward(userId, payload)
            .then(res=>res)
            .then(resp=>{
                console.log(resp);
                toast.success(resp.data.message);
                btn.innerHTML=`Save Changes`;
            })
            .catch((err)=>{
                console.log(err);
                btn.innerHTML=`Save Changes`;
            })
    }

    return(
        <div>
            <ToastContainer closeButton={false} />
            <div className="dashboard-content-form-edu" id="formMain" >
                <h3>Awards & Certification</h3>
                <div>
                    <div>
                        {form1 && 
                            <div className="dashboard-content-form-edu-add">
                                <div className="dashboard-content-form-container">
                                    <label>Award/Certificate Title</label><br/>
                                    <input type="text" name="title" defaultValue={award1.title} onChange={handleChange1} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Awarder/Certifier</label><br/>
                                    <input type="text" name="awarder" defaultValue={award1.awarder} onChange={handleChange1} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Certificate Link</label><br/>
                                    <input type="text" name="certificate_link" defaultValue={award1.certificate_link} onChange={handleChange1} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Year Awarded</label><br/>
                                    <select name="monthAwarded" onChange={handleChange1} >
                                        <option value={award1.monthAwarded}>{award1.monthAwarded}</option>
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </select>
                                    <select name="yearAwarded" onChange={handleChange1} >
                                        <option value={award1.yearAwarder}>{award1.yearAwarded}</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                    </select>
                                </div>
                                {/* <div className="dashboard-content-form-actions">
                                    <div className="dashboard-content-form-actions-delete">
                                        <img src={deleteForm} onClick={()=>deleteSomeForm("form1")} alt="delete"/>
                                    </div>
                                    {!form2 && <img src={addForm} onClick={()=>createNewForm("form1")} alt="add" className="dashboard-content-form-actions-add" />}
                                </div> */}
                            </div>
                        }
                        {form2 && 
                            <div className="dashboard-content-form-edu-add">
                                <div className="dashboard-content-form-container">
                                    <label>Award/Certificate Title</label><br/>
                                    <input type="text" name="title" defaultValue={award2.title} onChange={handleChange2} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Awarder/Certifier</label><br/>
                                    <input type="text" name="awarder" defaultValue={award2.awarder} onChange={handleChange2} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Certificate Link</label><br/>
                                    <input type="text" name="certificate_link" defaultValue={award2.certificate_link} onChange={handleChange2} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Year Awarded</label><br/>
                                    <select name="monthAwarded" onChange={handleChange2} >
                                        <option value={award2.monthAwarded}>{award2.monthAwarded}</option>
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </select>
                                    <select name="yearAwarded" onChange={handleChange2} >
                                        <option value={award2.yearAwarder}>{award2.yearAwarded}</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                    </select>
                                </div>
                                {/* <div className="dashboard-content-form-actions">
                                    {form1 &&
                                        <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form2")} alt="delete"/>
                                        </div>
                                    }
                                    {!form3 &&<img src={addForm} onClick={()=>createNewForm("form2")} alt="add" className="dashboard-content-form-actions-add" />}
                                </div> */}
                            </div>
                        }
                        {form3 && 
                            <div className="dashboard-content-form-edu-add">
                                <div className="dashboard-content-form-container">
                                    <label>Award/Certificate Title</label><br/>
                                    <input type="text" name="title" defaultValue={award3.title} onChange={handleChange3} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Awarder/Certifier</label><br/>
                                    <input type="text" name="awarder" defaultValue={award3.awarder} onChange={handleChange3} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Certificate Link</label><br/>
                                    <input type="text" name="certificate_link" defaultValue={award3.certificate_link} onChange={handleChange3} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Year Awarded</label><br/>
                                    <select name="monthAwarded" onChange={handleChange3} >
                                        <option value={award3.monthAwarded}>{award3.monthAwarded}</option>
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </select>
                                    <select name="yearAwarded" onChange={handleChange3} >
                                        <option value={award3.yearAwarder}>{award3.yearAwarded}</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                    </select>
                                </div>
                                <div className="dashboard-content-form-actions">
                                    {/* {form2 &&
                                        <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form3")} alt="delete"/>
                                        </div>
                                    }
                                    {!form4 &&<img src={addForm} onClick={()=>createNewForm("form3")} alt="add" className="dashboard-content-form-actions-add" />} */}
                                </div>
                            </div>
                        }
                        {form4 && 
                            <div className="dashboard-content-form-edu-add">
                                <div className="dashboard-content-form-container">
                                    <label>Award/Certificate Title</label><br/>
                                    <input type="text" name="title" defaultValue={award4.title} onChange={handleChange4} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Awarder/Certifier</label><br/>
                                    <input type="text" name="awarder" defaultValue={award4.awarder} onChange={handleChange4} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Certificate Link</label><br/>
                                    <input type="text" name="certificate_link" defaultValue={award4.certificate_link} onChange={handleChange4} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Year Awarded</label><br/>
                                    <select name="monthAwarded" onChange={handleChange4} >
                                        <option value={award4.monthAwarded}>{award4.monthAwarded}</option>
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </select>
                                    <select name="yearAwarded" onChange={handleChange4} >
                                        <option value={award4.yearAwarder}>{award4.yearAwarded}</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                    </select>
                                </div>
                                <div className="dashboard-content-form-actions">
                                    {/* {form3 &&
                                        <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form4")} alt="delete"/>
                                        </div>
                                    }
                                    {!form5 &&<img src={addForm} onClick={()=>createNewForm("form4")} alt="add" className="dashboard-content-form-actions-add" />} */}
                                </div>
                            </div>
                        }
                        {form5 && 
                            <div className="dashboard-content-form-edu-add">
                                <div className="dashboard-content-form-container">
                                    <label>Award/Certificate Title</label><br/>
                                    <input type="text" name="title" defaultValue={award5.title} onChange={handleChange5} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Awarder/Certifier</label><br/>
                                    <input type="text" name="awarder" defaultValue={award5.awarder} onChange={handleChange5} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Certificate Link</label><br/>
                                    <input type="text" name="certificate_link" defaultValue={award5.certificate_link} onChange={handleChange5} />
                                </div>
                                <div className="dashboard-content-form-container">
                                    <label>Year Awarded</label><br/>
                                    <select name="monthAwarded" onChange={handleChange5} >
                                        <option value={award5.monthAwarded}>{award5.monthAwarded}</option>
                                        <option value="Jan">January</option>
                                        <option value="Feb">February</option>
                                        <option value="Mar">March</option>
                                        <option value="Apr">April</option>
                                        <option value="May">May</option>
                                        <option value="Jun">June</option>
                                        <option value="Jul">July</option>
                                        <option value="Aug">August</option>
                                        <option value="Sep">September</option>
                                        <option value="Oct">October</option>
                                        <option value="Nov">November</option>
                                        <option value="Dec">December</option>
                                    </select>
                                    <select name="yearAwarded" onChange={handleChange5} >
                                        <option value={award5.yearAwarder}>{award5.yearAwarded}</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                    </select>
                                </div>
                                <div className="dashboard-content-form-actions">
                                    {/* <div className="dashboard-content-form-actions-delete">
                                        <img src={deleteForm} onClick={()=>deleteSomeForm("form5")} alt="delete"/>
                                    </div>
                                    <img src={addForm} onClick={()=>createNewForm("form5")} alt="add" className="dashboard-content-form-actions-add" /> */}
                                </div>
                            </div>
                        }
                        <div className="dashboard-content-form-edu-submit" >
                            <button onClick={submitForm} id="save">Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}


export default Awards;