import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
// import OutsideClickHandler from "react-outside-click-handler";
import {
  getSingleCommercialUser
} from "../../lib/data";
import ReactToPrint from "react-to-print";

import arrow from "../../images/svg/arrow-back.svg";
import download from "../../images/svg/download.svg";
import Loader from "../Loader";
import axios from "axios";

function Talentcommercialdetails({setTabOption}) {
  const [clicked, setClicked] = useState("bio");
  const [guarantorDetails, setGuarantorDetails] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [changeStatus, setChangeStatus] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [downloadHtml, setDownloadHtml] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get("user");


  useEffect(() => {
    getSingleCommercialUser(user_id)
      .then((response) => {
        if (response.status === 200) {
          setUserDetails(response.data.data.data);
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response) {
          toast.error(err.response);
        } else {
          toast.error(
            "Something went wrong!!! Please make sure you have a good internet connection."
          );
        }
      });
  }, []);

    const downloadResp = (resp) => {
    setDownloadHtml(resp);
  };

  return (
    <div>
      {userDetails ? (
        <div className="dashboard-content">
          <ToastContainer closeButton={false} />
          <div
            className="dashboard-content-back"
            onClick={() => {
              window.history.back()
              setTabOption('interest')
            }}
          >
            <img src={arrow} alt="back" />
            <p>Back</p>
          </div>
          <div className="disp-flex dashboard-content-detail-actions">
            <div></div>
            <h3>
              {userDetails.businessName}
            </h3>
            <div className="disp-flex dashboard-content-actions-div">
              <ReactToPrint
                content={() => downloadHtml}
                trigger={() => <p>Download Data</p>}
              />
              <div className="pos-rel">
                {showDownload && (
                    <div className="dashboard-content-actions-byStatus new-option">
                      <ReactToPrint
                        content={() => downloadHtml}
                        trigger={() => (
                          <div className="status">
                            <label htmlFor="under-review">Bio Data</label>
                            <img
                              src={download}
                              alt="download"
                              style={{ marginLeft: "auto" }}
                            />
                          </div>
                        )}
                      />
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-content-detail">
            <p
              className={
                clicked === "bio"
                  ? "dashboard-content-detail-p active"
                  : "dashboard-content-detail-p"
              }
              onClick={() => setClicked("bio")}
            >
              Bio Data
            </p>
          </div>
          {clicked === "bio" && (
            <div
              className="dashboard-content-form"
              id="noshadow"
              ref={(response) => downloadResp(response)}
            >
              <form>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Business Name</h5>
                  <p className="text">{userDetails?.businessName}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Email Address</h5>
                  <p className="text">{userDetails?.email}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Phone Number</h5>
                  <p className="text">{userDetails?.phoneNumber}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Investment Amount</h5>
                  <p className="text">{userDetails?.investmentAmount}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Intended Use Case</h5>
                  <p className="text">{userDetails?.intendedUseCase}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Infrastructure/Facility needed</h5>
                  <p className="text">{userDetails?.facilityNeeded}</p>
                </div>
                <div className="dashboard-content-form-container">
                  <h5 className="heading">Inspection Fee</h5>
                  <p className="text fee paid">{userDetails?.inspectionFee ? 'paid': ''}</p>
                </div>
              </form>
            </div>
          )}
          
          
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Talentcommercialdetails;
