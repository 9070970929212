import axios from 'axios';

//tis creates axios client pre-configured with base URL

let APIKit = axios.create({
    // baseURL: 'https://admin-api.grazacacademy.com/api/v1/admin',
    // baseURL: 'https://grazac-admin-backend-d48022f1cdb6.herokuapp.com/',
    baseURL: 'https://grazac-academy-admin.onrender.com/',
    timeout: 20000, // timeout can be i5ncreased
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
});
export const APIKit2 = axios.create({
    // baseURL: 'https://admin-api.grazacacademy.com/api/v1/admin',
    // baseURL: 'https://grazac-admin-backend-d48022f1cdb6.herokuapp.com/',
    baseURL: 'https://grzac-talent-city-backend.onrender.com/',
    timeout: 20000, // timeout can be i5ncreased
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
});

export default APIKit;