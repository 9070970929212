import React from 'react'
import Sidebar from '../component/dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import Web3applicants from '../component/dashboard/Web3applicants';
import Web3details from '../component/dashboard/Web3details';

const Web3 = () => {
    return(
        <div>
            <ToastContainer closeButton={false} />
            {/* <OutsideClickHandler
                onOutsideClick={() => {
                    closeNavMobile();
                }}
            > */}
                <div className="layout_dashboard-sidebar" id="sidebar">
                    <Sidebar />
                </div>
            {/* </OutsideClickHandler> */}
            <div className="layout_dashboard-content">
                <div className="dashboard-nav">
                    {/* <img src={menu} alt="menu" className="dashboard-nav-menu" onClick={showNavMobile} /> */}
                    <div className="dashboard-nav-user">
                        <div>
                            <h2>AD</h2>
                        </div>
                        <p>Admin</p>
                    </div>
                </div>

                <Routes>
                    <Route path="/" exact element={<Web3applicants  />} />
                    <Route path="web3details" element={<Web3details />} />
                    {/* <Route path="/hire" element={<HireMain/>} />
                    <Route path="/hireDetails" element={<HireDetails/>} />
                    <Route path="/editDetails" element={<EditDetails/>} /> */}
                </Routes>
            </div>
        </div>
    );
}

export default Web3