import { useEffect, useState } from "react";
import { getAllCities, getAllStates } from "../../../lib/locationApi";
import { addBasicData } from "../../../lib/hire";

import loader from "../../../images/gif/loader.gif";
// import Loader from "../../Loader";
import loader_gif from "../../../images/gif/loader-purple.gif";


function BasicInfo({ userId, addOrFetch, next, user, fetched }) {
    const [loading, setLoading] = useState(true);

  const [profilePic, setProfilePic] = useState(null);
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profession: "",
    city: "",
    state: "",
    bio: "",
    twitter: "",
    instagram: "",
    dribble: "",
    github: "",
    linkedin: "",
  });
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    getAllCities()
      .then((res) => res)
      .then((resp) => {
        setCities(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllStates()
      .then((res) => res)
      .then((resp) => {
        setStates(resp.data.data.states);
        setLoading(false)
      })
      .catch((err) => {
        
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleImage = (e) => {
    console.log(e.target.files[0].size)
    console.log(e.target.files[0])
    if (e.target.files[0].size > 2097152) {
      alert("File too large! A maximum of 2MB is allowed.");
    } else {
      setProfilePic(e.target.files[0]);
      const show = document.getElementById("pic");
      show.src = window.URL.createObjectURL(e.target.files[0]);
    }
  };

  const uploadTrigger = (e) => {
    e.preventDefault();
    document.getElementById("inputFile").click();
  };

  const removePhoto = (e) => {
    e.preventDefault();
  };

  const submitBasicInfo = (e) => {
    e.preventDefault();
    const btn = document.getElementById("save");
    btn.innerHTML = `<img src=${loader} width="20px" />`;
    const payload = new FormData();
    payload.append("firstName", formDetails.firstName);
    payload.append("lastName", formDetails.lastName);
    payload.append("profession", formDetails.profession);
    payload.append("location", `${formDetails.city}, ${formDetails.state}`);
    payload.append("bio", formDetails.bio);
    payload.append("profile_upload", profilePic);
    payload.append("twitter", formDetails.twitter);
    payload.append("instagram", formDetails.instagram);
    payload.append("email", formDetails.email);
    payload.append("dribble", formDetails.dribble);
    payload.append("github", formDetails.github);
    payload.append("behance", formDetails.linkedin); //should be linkein instead

    console.log(payload);
    addBasicData(payload)
      .then((res) => res)
      .then((resp) => {
        userId(resp.data.data.id);
        next();
        
        btn.innerHTML = `Save`;
        
      })
      .catch((err) => {
        console.log(err);
        alert("There was an error, please complete all the fields and try again")
        btn.innerHTML = `Save`;
      });
  };

  return (
    <div>
      {console.log(cities.length, states.length)}
      {states.length != 0 && cities.length != 0 ? (
        <div className="dashboard-content-form-basic">
          <h3>Basic Information</h3>
          {fetched ? (
            <div style={{ padding: "0 30px 50px" }}>
              <div className="dashboard-content-form-basic-upload-profile">
                <img src={user.user?.profile_upload} alt="profile" />
              </div>
              <div className="dashboard-content-form-container">
                <h5>Full Name</h5>
                <p>
                  {user.user?.firstName} {user.user?.lastName}
                </p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Status</h5>
                <p>{user.user?.status}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Email Address</h5>
                <p>{user.user?.email}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Location</h5>
                <p>{user.user?.location}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Profession</h5>
                <p>{user.user?.profession}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Bio</h5>
                <p>{user.user?.shortBio}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>GitHub</h5>
                <p>{user.user?.github}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>LinkedIn</h5>
                <p>{user.user?.linkedin}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Twitter</h5>
                <p>{user.user?.twitter}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Instagram</h5>
                <p>{user.user?.instagram}</p>
              </div>
              <hr />
              <div className="dashboard-content-form-container">
                <h5>Dribble</h5>
                <p>{user.user?.dribble}</p>
              </div>
            </div>
          ) : (
            <form onSubmit={submitBasicInfo}>
              <div className="dashboard-content-form-basic-upload">
                <div className="dashboard-content-form-basic-upload-profile">
                  <img id="pic" />
                </div>
                <div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="inputFile"
                    onChange={handleImage}
                  />
                  <button
                    className="dashboard-content-form-basic-upload-btn1"
                    onClick={uploadTrigger}
                  >
                    Upload Picture
                  </button>
                  <br />
                  {addOrFetch === "none" ? (
                    <button
                      className="dashboard-content-form-basic-upload-btn2"
                      onClick={removePhoto}
                    >
                      Remove Photo
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  <p>
                    *JPG, *PNG file formats supported
                    <br />
                    2MB max, file size.
                  </p>
                </div>
              </div>
              <div className="dashboard-content-form-container">
                <label>First Name</label>
                <br />
                <input
                  type="text"
                  required
                  name="firstName"
                  placeholder="FirstName"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  required
                  name="lastName"
                  placeholder="LastName"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Email Address</label>
                <br />
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Profession</label>
                <br />
                <input
                  type="text"
                  required
                  name="profession"
                  placeholder="Profession"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Location</label>
                <br />

                <select name="state" required onChange={handleChange}>
                  <option value="">Select State</option>
                  {states?.map((item, idx) => (
                    <option value={item.name} key={idx}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {console.log(states, cities)}

                <select name="city" required onChange={handleChange}>
                  <option value="">Select City</option>
                  {cities?.map((item, idx) => (
                    <option value={item} key={idx}>
                      {item}
                    </option>
                  ))}
                </select>
                <br />
              </div>
              <div className="dashboard-content-form-container">
                <label>Short Bio</label>
                <br />
                <textarea
                  type="text"
                  required
                  name="bio"
                  placeholder="Short Bio"
                  onChange={handleChange}
                />
              </div>
              <h4>Social Media Links</h4>
              <div className="dashboard-content-form-container">
                <label>Twitter</label>
                <br />
                <input
                  type="text"
                  name="twitter"
                  placeholder="Input your social media link"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Instagram</label>
                <br />
                <input
                  type="text"
                  name="instagram"
                  placeholder="Input your social media link"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>Dribble</label>
                <br />
                <input
                  type="text"
                  name="dribble"
                  placeholder="Input your social media link"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>GitHub</label>
                <br />
                <input
                  type="text"
                  name="github"
                  placeholder="Input your social media link"
                  onChange={handleChange}
                />
              </div>
              <div className="dashboard-content-form-container">
                <label>LinkedIn</label>
                <br />
                <input
                  type="text"
                  name="linkedin"
                  placeholder="Input your social media link"
                  onChange={handleChange}
                />
              </div>
              <div
                className="dashboard-content-form-edu-submit"
                style={{ marginTop: "40px" }}
              >
                <button type="submit" id="save">
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      ) : (
        <div>
          {loading && (
            <img
              src={loader_gif}
              alt="loader_gif"
              style={{ width: "100px", margin: "50px auto", display: "block" }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default BasicInfo;
