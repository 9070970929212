import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { useState } from "react";

import Logo from "../../images/svg/academy-logo.svg";

function Sidebar() {
  const [clickLink, setClickLink] = useState(false);
  const [selBatch, setSelBatch] = useState("batchb");

  console.log(clickLink);

  const signOut = () => {
    sessionStorage.clear();
    toast.info("Signing out ...");
    setTimeout(function () {
      // window.location = "/dashboard";
      window.location = "/";
    }, 2500);
  };

  return (
    <div className="sidebar">
      <ToastContainer closeButton={false} />
      <Link to="/">
        <img src={Logo} alt="logo" className="logo" />
      </Link>
      <div className="sidebar-portal">
        <p className="sidebar-portal-heading">PORTAL</p>
        {/* <Link to="/" className="link-decor"> */}
          {/* className="active" goes for the active link */}

          {/* <div className="inactive">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5 18.0003C18.5 18.2655 18.3946 18.5198 18.2071 18.7074C18.0196 18.8949 17.7652 19.0003 17.5 19.0003H1.5C1.23478 19.0003 0.98043 18.8949 0.792893 18.7074C0.605357 18.5198 0.5 18.2655 0.5 18.0003V7.49027C0.499895 7.33788 0.534617 7.18749 0.601516 7.05057C0.668415 6.91365 0.76572 6.79384 0.886 6.70027L8.886 0.478272C9.06154 0.341717 9.2776 0.267578 9.5 0.267578C9.7224 0.267578 9.93846 0.341717 10.114 0.478272L18.114 6.70027C18.2343 6.79384 18.3316 6.91365 18.3985 7.05057C18.4654 7.18749 18.5001 7.33788 18.5 7.49027V18.0003ZM4.5 13.0003V15.0003H14.5V13.0003H4.5Z"
                fill="#787676"
              />
            </svg>
            <div className="sidebar-portal-dashboardlink">
              <div className="sidebar-portal-dashboardlink-main">
                <p>Dashboard</p>

                <span>
                  {clickLink ? (
                    <AiFillCaretDown size={17} />
                  ) : (
                    <AiFillCaretRight size={17} />
                  )}
                </span>
              </div>
              {clickLink && (
                <>
                  <Link to="/batcha">
                    <div
                      className={
                        selBatch === "batcha"
                          ? "active sidebar-portal-dashboardlink-mainlink"
                          : "sidebar-portal-dashboardlink-mainlink"
                      }
                      onClick={() => setSelBatch("batcha")}
                    >
                      <p>Batch A</p>
                    </div>{" "}
                  </Link>
                  {console.log(selBatch)}
                  <Link to="/batchb">
                    <div
                      className={
                        selBatch === "batchb"
                          ? "active sidebar-portal-dashboardlink-mainlink"
                          : "sidebar-portal-dashboardlink-mainlink"
                      }
                      onClick={() => setSelBatch("batchb")}
                    >
                      <p>Batch B</p>
                    </div>
                  </Link>
                </>
              )}
            </div>
          </div> */}
        {/* </Link> */}

        <div className="link-decor">
          <Link to='/dashboard' className="link-decoration">
            <div className="inactive">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5 18.0003C18.5 18.2655 18.3946 18.5198 18.2071 18.7074C18.0196 18.8949 17.7652 19.0003 17.5 19.0003H1.5C1.23478 19.0003 0.98043 18.8949 0.792893 18.7074C0.605357 18.5198 0.5 18.2655 0.5 18.0003V7.49027C0.499895 7.33788 0.534617 7.18749 0.601516 7.05057C0.668415 6.91365 0.76572 6.79384 0.886 6.70027L8.886 0.478272C9.06154 0.341717 9.2776 0.267578 9.5 0.267578C9.7224 0.267578 9.93846 0.341717 10.114 0.478272L18.114 6.70027C18.2343 6.79384 18.3316 6.91365 18.3985 7.05057C18.4654 7.18749 18.5001 7.33788 18.5 7.49027V18.0003ZM4.5 13.0003V15.0003H14.5V13.0003H4.5Z"
                fill="#787676"
              />
            </svg>
              <p>Dashboard</p>
            </div>
          </Link>
        </div>

        {/* <Link to="/hire" className="link-decor"> */}
        <div className="link-decor">
          <div className="inactive">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20ZM9.5 9V15H11.5V9H9.5ZM9.5 5V7H11.5V5H9.5Z"
                fill="#333333"
              />
            </svg>
            <p>Hire</p>
          </div>
        </div>
        <div className="link-decor">
          <Link to='/web3' className="link-decoration">
            <div className="inactive">
            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 10C11.83 10 6.5 11.33 6.5 14V16H22.5V14C22.5 11.33 17.17 10 14.5 10ZM5.5 6V3H3.5V6H0.5V8H3.5V11H5.5V8H8.5V6M14.5 8C15.5609 8 16.5783 7.57857 17.3284 6.82843C18.0786 6.07828 18.5 5.06087 18.5 4C18.5 2.93913 18.0786 1.92172 17.3284 1.17157C16.5783 0.421427 15.5609 0 14.5 0C13.4391 0 12.4217 0.421427 11.6716 1.17157C10.9214 1.92172 10.5 2.93913 10.5 4C10.5 5.06087 10.9214 6.07828 11.6716 6.82843C12.4217 7.57857 13.4391 8 14.5 8Z" fill="#333333"/>
            </svg>
              <p>Web3 Applicants</p>
            </div>
          </Link>
        </div>
        <div className="link-decor">
          <Link to='/talent-city' className="link-decoration">
            <div className="inactive">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.207 4.793C9.914 4.5 9.443 4.5 8.5 4.5H8.25V3C8.25 2.80109 8.17098 2.61032 8.03033 2.46967C7.88968 2.32902 7.69891 2.25 7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V4.5H6.5C5.557 4.5 5.086 4.5 4.793 4.793C4.5 5.086 4.5 5.557 4.5 6.5V6.704C4.70333 6.652 4.90533 6.613 5.106 6.587C5.75 6.5 6.545 6.5 7.41 6.5H7.589C8.454 6.5 9.248 6.5 9.893 6.587C10.0943 6.61233 10.2963 6.65133 10.499 6.704V6.5C10.499 5.557 10.5 5.086 10.207 4.793Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 21.25C2.30109 21.25 2.11032 21.329 1.96967 21.4697C1.82902 21.6103 1.75 21.8011 1.75 22C1.75 22.1989 1.82902 22.3897 1.96967 22.5303C2.11032 22.671 2.30109 22.75 2.5 22.75H22.5C22.6989 22.75 22.8897 22.671 23.0303 22.5303C23.171 22.3897 23.25 22.1989 23.25 22C23.25 21.8011 23.171 21.6103 23.0303 21.4697C22.8897 21.329 22.6989 21.25 22.5 21.25H21.5V7.77199C21.5 6.43199 21.5 5.76099 21.144 5.24699C20.788 4.73299 20.16 4.49699 18.904 4.02699C16.449 3.10599 15.222 2.64599 14.361 3.24199C13.5 3.83999 13.5 5.14999 13.5 7.77199V10.5H13.59C14.455 10.5 15.249 10.5 15.894 10.587C16.605 10.682 17.357 10.907 17.974 11.525C18.593 12.143 18.818 12.895 18.914 13.605C19 14.251 19 15.045 19 15.911V21.25H17.5V16C17.5 14.114 17.5 13.172 16.914 12.586C16.328 12 15.386 12 13.5 12H11.5C9.614 12 8.672 12 8.086 12.586C7.5 13.172 7.5 14.114 7.5 16V21.25H6V15.91C6 15.045 6 14.251 6.087 13.606C6.182 12.895 6.407 12.143 7.025 11.526C7.643 10.907 8.395 10.682 9.105 10.586C9.751 10.5 10.545 10.5 11.411 10.5H11.483C11.443 9.56399 11.318 8.98999 10.914 8.58599C10.328 7.99999 9.386 7.99999 7.5 7.99999C5.614 7.99999 4.672 7.99999 4.086 8.58599C3.5 9.17199 3.5 10.114 3.5 12V21.25H2.5ZM9.75 15C9.75 14.8011 9.82902 14.6103 9.96967 14.4697C10.1103 14.329 10.3011 14.25 10.5 14.25H14.5C14.6989 14.25 14.8897 14.329 15.0303 14.4697C15.171 14.6103 15.25 14.8011 15.25 15C15.25 15.1989 15.171 15.3897 15.0303 15.5303C14.8897 15.671 14.6989 15.75 14.5 15.75H10.5C10.3011 15.75 10.1103 15.671 9.96967 15.5303C9.82902 15.3897 9.75 15.1989 9.75 15ZM9.75 18C9.75 17.8011 9.82902 17.6103 9.96967 17.4697C10.1103 17.329 10.3011 17.25 10.5 17.25H14.5C14.6989 17.25 14.8897 17.329 15.0303 17.4697C15.171 17.6103 15.25 17.8011 15.25 18C15.25 18.1989 15.171 18.3897 15.0303 18.5303C14.8897 18.671 14.6989 18.75 14.5 18.75H10.5C10.3011 18.75 10.1103 18.671 9.96967 18.5303C9.82902 18.3897 9.75 18.1989 9.75 18Z" fill="black"/>
            </svg>
              <p>Talent City</p>
            </div>
          </Link>
        </div>

        <div className="sidebar-portal-links signout" onClick={signOut}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM5 9V6L0 10L5 14V11H13V9H5Z"
              fill="#909198"
            />
          </svg>
          <p>Sign Out</p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
