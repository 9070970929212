import { useState } from "react";
import { addEducationData } from "../../../lib/hire";

import addForm from "../../../images/add-form.png";
import deleteForm from "../../../images/delete-form.png";
import loader from "../../../images/gif/loader.gif";

function Education({ userId, next, user, fetched }) {
  const [inputDetails, setInputDetails] = useState([
    {
      institution: "",
      course: "",
      qualification: "",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const handleChange = (event, index) => {
    // console.log(event.target.value);
    let data = [...inputDetails];
    if (event.target.value === "") {
      // can't be
      alert("Please fill the form to proceed");
    }

    data[index][event.target.name] = event.target.value;
    setInputDetails(data);
    
    // console.log(inputDetails);
  };
  const addNewForm = () => {
    let newForm = {
      institution: "",
      course: "",
      qualification: "",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    };

    setInputDetails([...inputDetails, newForm]);
  };

  const submitDynamicForm=()=>{
    const payload = JSON.stringify({
      info: [inputDetails],
    });
    mainSubmitAction(payload);
    console.log(payload)
    
  }




  const mainSubmitAction = (payload) => {
    const btn = document.getElementById("save");
    console.log(userId);
    addEducationData(userId, payload)
      .then((res) => res)
      .then((resp) => {
        console.log(resp);
        next();
        btn.innerHTML = `Save`;
      })
      .catch((err) => {
        console.log(err);
        btn.innerHTML = `Save`;
      });
  };

  return (
    <div>
      <div className="dashboard-content-form-edu" id="formMain">
        <h3>Education</h3>
        <div>
          {fetched ? (
            <div>
              {user.education?.map((item, idx) => (
                <div style={{ padding: "0 40px 50px" }} key={idx}>
                  <div className="dashboard-content-form-container">
                    <h5>Name of Institution</h5>
                    <p>{item.institution}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Course of Study</h5>
                    <p>{item.course}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Qualification</h5>
                    <p>{item.qualification}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Start Year</h5>
                    <p>{item.startYear}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>End Year</h5>
                    <p>{item.endYear}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {inputDetails.map((input, index) => {
                return (
                  <form onSubmit={submitDynamicForm}>
                  <div key={index}>
                    <div className="dashboard-content-form-edu-add">
                      <div className="dashboard-content-form-container">
                        <label>Name of Institution</label>
                        <br />
                        <input
                          type="text"
                          name="institution"
                          placeholder="Name of Institution"
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                      {/* {console.log(inputDetails.institution)} */}
                      <div className="dashboard-content-form-container">
                        <label>Course of Study</label>
                        <br />
                        <input
                          type="text"
                          name="course"
                          placeholder="Course of Study"
                          value={inputDetails.course}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                      <div className="dashboard-content-form-container">
                        <label>Qualification</label>
                        <br />
                        <input
                          type="text"
                          name="qualification"
                          placeholder="Qualification"
                          value={inputDetails.qualification}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                      <div className="dashboard-content-form-container">
                        <label>Start Date</label>
                        <br />
                        <select
                          name="startMonth"
                          value={inputDetails.startMonth}
                          onChange={(event) => handleChange(event, index)}
                        >
                          <option value="">Select Start Month</option>
                          <option value="Jan">January</option>
                          <option value="Feb">February</option>
                          <option value="Mar">March</option>
                          <option value="Apr">April</option>
                          <option value="May">May</option>
                          <option value="Jun">June</option>
                          <option value="Jul">July</option>
                          <option value="Aug">August</option>
                          <option value="Sep">September</option>
                          <option value="Oct">October</option>
                          <option value="Nov">November</option>
                          <option value="Dec">December</option>
                        </select>
                        <select
                          name="startYear"
                          value={inputDetails.startYear}
                          onChange={(event) => handleChange(event, index)}
                        >
                          <option value="">Select Start Year</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                        </select>
                      </div>
                      <div className="dashboard-content-form-container">
                        <label>End Year</label>
                        <br />
                        <select
                          name="endMonth"
                          value={inputDetails.endMonth}
                          onChange={(event) => handleChange(event, index)}
                        >
                          <option value="">Select End Month</option>
                          <option value="Jan">January</option>
                          <option value="Feb">February</option>
                          <option value="Mar">March</option>
                          <option value="Apr">April</option>
                          <option value="May">May</option>
                          <option value="Jun">June</option>
                          <option value="Jul">July</option>
                          <option value="Aug">August</option>
                          <option value="Sep">September</option>
                          <option value="Oct">October</option>
                          <option value="Nov">November</option>
                          <option value="Dec">December</option>
                        </select>
                        <select
                          name="endYear"
                          value={inputDetails.endYear}
                          onChange={(event) => handleChange(event, index)}
                        >
                          <option value="">Select End Year</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  </form>
                );

              })}
           
              <div className="dashboard-content-form-actions">
                <img
                  src={addForm}
                  onClick={addNewForm}
                  alt="add"
                  className="dashboard-content-form-actions-add"
                />
              </div>
              <div className="dashboard-content-form-edu-submit">
                <button onClick={submitDynamicForm} id="save">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Education;
