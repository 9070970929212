import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getOne } from "../../lib/hire";
import BasicInfo from "./forms/BasicInfo";
import Education from "./forms/Education";
import Awards from "./forms/Awards";
import Projects from "./forms/Projects";

import arrow from "../../images/svg/arrow-back.svg"


function AddNew () {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('user');
    const [info, setInfo] = useState("basic");
    const [use_id, setUse_id] = useState(null);
    const [singleUser, setSingleUser] = useState({});
    const [fetchedUser, setFetchedUser] = useState(false)

    useEffect(()=>{
        if(user_id==="none"){
            console.log("it is null");

        } else {
            setFetchedUser(true)
            //fetch user details
            getOne(user_id)
                .then(response => response.data)
                .then(resp=>{
                    console.log(resp)
                    setSingleUser(resp.profile);
                })
                .catch(err=>{
                    console.log(err);
                    window.location.reload();
                })
        }
    }, [])
    return(
        <div className="dashboard-content" >
            <ToastContainer closeButton={false} />
            <div className="dashboard-content-back" onClick={() => window.history.back()} >
                <img src={arrow} alt="back" />
                {fetchedUser?<p>Talent Details</p>:<p>Add New</p>}
            </div>
            <div className="dashboard-content-form">
                {fetchedUser ? 
                    <div className="dashboard-content-form-textOption">
                        <h6 className={info==="basic"?"active":""} onClick={()=>setInfo("basic")}>Basic Information</h6>
                        <h6 className={info==="education"?"active":""} onClick={()=>setInfo("education")} >Education</h6>
                        <h6 className={info==="award"?"active":""} onClick={()=>setInfo("award")}>Award &#38; Certification</h6>
                        <h6 className={info==="project"?"active":""} onClick={()=>setInfo("project")}>Project &#38; Experience</h6>
                    </div>
                    :
                    <div className="dashboard-content-form-textOption">
                        <h6 className={info==="basic"?"active":""}>Basic Information</h6>
                        <h6 className={info==="education"?"active":""}>Education</h6>
                        <h6 className={info==="award"?"active":""}>Award & Certification</h6>
                        <h6 className={info==="project"?"active":""}>Project & Experience</h6>
                    </div>
                }
                <div>
                    {info==="basic" && 
                        <BasicInfo 
                            userId={(id)=>setUse_id(id)} 
                            addOrFetch={user_id} 
                            next={()=>setInfo("education")} 
                            user={singleUser}
                            fetched={fetchedUser}
                        />
                    }
                    {info==="education" && 
                        <Education 
                            userId={use_id} 
                            next={()=>setInfo("award")}
                            user={singleUser}
                            fetched={fetchedUser}
                        />
                    }
                    {info==="award" && 
                        <Awards 
                            userId={use_id} 
                            next={()=>setInfo("project")} 
                            user={singleUser}
                            fetched={fetchedUser}
                        />
                    }
                    {info==="project" && 
                        <Projects 
                            userId={use_id} 
                            next={()=>window.location = `/hireDetails?user=${use_id}`} 
                            user={singleUser}
                            fetched={fetchedUser}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default AddNew;