import { useEffect, useState } from "react";
import {getAllCities, getAllStates} from "../../../../lib/locationApi";
import { getOne, editBasicData } from "../../../../lib/hire";
import { ToastContainer, toast } from "react-toastify";

import loader from "../../../../images/gif/loader.gif";

function BasicInfo ({userId}) {
    const [profilePic, setProfilePic] = useState(null);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        profession: "",
        location:  "",
        bio: "",
        twitter: "",
        instagram: "",
        dribble: "",
        github: "",
        linkedin: ""
    });
    const [states, setStates] = useState([]);

    useEffect(()=>{
        // console.log(userId)
        getAllStates()
            .then(res=>res)
            .then(resp=>{
                setStates(resp.data.data.states)
            })
            .catch((err)=>{
                console.log(err)
            })
        getOne(userId)
            .then(response => response.data)
            .then(resp=>{
                // console.log(resp)
                setUser({
                    firstName: resp.profile.user.firstName,
                    lastName: resp.profile.user.lastName,
                    email: resp.profile.user.email,
                    profession: resp.profile.user.profession,
                    location:  resp.profile.user.location,
                    bio: resp.profile.user.shortBio,
                    twitter: resp.profile.user.twitter,
                    instagram: resp.profile.user.instagram,
                    dribble: resp.profile.user.dribble,
                    github: resp.profile.user.github,
                    linkedin: resp.profile.user.linkedin
                });
            })
            .catch(err=>{
                console.log(err);
                // window.location.reload();
            })
    },[]);

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const handleImage = (e) => {
        if (e.target.files[0].size > 2097152){
            alert("File too large! A maximum of 2MB is allowed.")
        } else{
            setProfilePic(e.target.files[0]);
            const show = document.getElementById("pic");
            show.src = window.URL.createObjectURL(e.target.files[0])
        }
    }

    const uploadTrigger = (e) => {
        e.preventDefault();
        document.getElementById("inputFile").click();
    }

    const removePhoto = (e) => {
        e.preventDefault();
        alert("You can't delete profile pictures for now!!!")
    }

    const editBasicInfo = (e) => {
        e.preventDefault();
        const btn = document.getElementById("save");
        btn.innerHTML=`<img src=${loader} width="20px" />`;
        const payload = new FormData();
        payload.append('firstName', user.firstName);
        payload.append('lastName', user.lastName);
        payload.append('profession', user.profession);
        payload.append('location', user.location);
        payload.append('shortBio', user.bio);
        // payload.append('profile_upload', profilePic);
        payload.append('twitter', user.twitter);
        payload.append('instagram', user.instagram);
        payload.append('email', user.email);
        payload.append('dribble', user.dribble);
        payload.append('github', user.github);
        payload.append('linkedin', user.linkedin); //should be linkein instead

        console.log(user)
        console.log(userId)

        editBasicData(userId, payload)
            .then(res=>res)
            .then(resp=>{
                console.log(resp)
                toast.success(resp.data.message);
                btn.innerHTML=`Save Changes`;
            })
            .catch((err)=>{
                console.log(err);
                btn.innerHTML=`Save Changes`;
            })
    }

    return (
        <div>
            <ToastContainer closeButton={false} />
            <div className="dashboard-content-form-basic">
                <h3>Basic Information</h3>
                <form onSubmit={editBasicInfo}>
                    <div className="dashboard-content-form-basic-upload">
                        <div className="dashboard-content-form-basic-upload-profile">
                            <img id="pic" src={user?.user?.profile_upload} />
                        </div>
                        <div>
                            <input type="file" style={{display:"none"}} id="inputFile" onChange={handleImage} />
                            <button className="dashboard-content-form-basic-upload-btn1" onClick={uploadTrigger}>Change Picture</button><br/>
                            <button className="dashboard-content-form-basic-upload-btn2" onClick={removePhoto} >Remove Photo</button>
                        </div>
                        <div>
                            <p>*JPG, *PNG file formats supported<br/>2MB max, file size.</p>
                        </div>
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>First Name</label><br/>
                        <input type="text" name="firstName" defaultValue={user?.firstName} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Last Name</label><br/>
                        <input type="text" name="lastName" defaultValue={user?.lastName} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Email Address</label><br/>
                        <input type="email" name="email" defaultValue={user?.email} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Profession</label><br/>
                        <input type="text" name="profession" defaultValue={user?.profession} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Location</label><br/>
                        <select name="location" onChange={handleChange}>
                            <option value={user?.location}>{user?.location}</option>
                            {states?.map((item,idx)=> (
                                    <option value={item.name} key={idx}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Short Bio</label><br/>
                        <textarea type="text" name="bio" defaultValue={user?.bio} onChange={handleChange} />
                    </div>
                    <h4>Social Media Links</h4>
                    <div className="dashboard-content-form-container">
                        <label>Twitter</label><br/>
                        <input type="text" name="twitter" defaultValue={user?.twitter} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Instagram</label><br/>
                        <input type="text" name="instagram" defaultValue={user?.instagram} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>Dribble</label><br/>
                        <input type="text" name="dribble" defaultValue={user?.dribble} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>GitHub</label><br/>
                        <input type="text" name="github" defaultValue={user?.github} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-container">
                        <label>LinkedIn</label><br/>
                        <input type="text" name="linkedin" defaultValue={user?.linkedin} onChange={handleChange} />
                    </div>
                    <div className="dashboard-content-form-edu-submit" style={{marginTop:"40px"}} >
                        <button type="submit" id="save">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BasicInfo;