import { useState } from "react";
import { addAwardData } from "../../../lib/hire";
import addForm from "../../../images/add-form.png";
import deleteForm from "../../../images/delete-form.png";
import loader from "../../../images/gif/loader.gif";

function Awards({ userId, next, user, fetched }) {
  const [awardDetails, setAwardDetails] = useState([
    {
      title: "",
      awarder: "",
      certificate_link: "",
      monthAwarded: "",
      yearAwarded: "",
    },
  ]);

  const handleChange = (event, index) => {
    // console.log(event.target.value);
    let data = [...awardDetails];
    if (event.target.value === "") {
      // can't be
      alert("Please fill the form to proceed");
    }

    data[index][event.target.name] = event.target.value;
    setAwardDetails(data);

    // console.log(inputDetails);
  };
  const addNewForm = () => {
    let newForm = {
      title: "",
      awarder: "",
      certificate_link: "",
      monthAwarded: "",
      yearAwarded: "",
    };

    setAwardDetails([...awardDetails, newForm]);
  };

  const submitDynamicForm = () => {
    const payload = JSON.stringify({
      info: [awardDetails],
    });
    mainSubmitAction(payload);
    console.log(payload);
  };

  const mainSubmitAction = (payload) => {
    const btn = document.getElementById("save");
    console.log(userId);
    addAwardData(userId, payload)
      .then((res) => res)
      .then((resp) => {
        console.log(resp);
        next();
        btn.innerHTML = `Save`;
      })
      .catch((err) => {
        console.log(err);
        btn.innerHTML = `Save`;
      });
  };

  return (
    <div>
      <div className="dashboard-content-form-edu" id="formMain">
        <h3>Awards & Certification</h3>
        <div>
          {fetched ? (
            <div>
              {user.award?.map((item, idx) => (
                <div style={{ padding: "0 40px 50px" }} key={idx}>
                  <div className="dashboard-content-form-container">
                    <h5>Award Title</h5>
                    <p>{item.title}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Awarder</h5>
                    <p>{item.awarder}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Year Awarded</h5>
                    <p>{item.yearAwarded}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {awardDetails.map((award, index) => {
                return (
                  <form action="" onSubmit={submitDynamicForm}>
                    <div key={index}>
                      <div className="dashboard-content-form-edu-add">
                        <div className="dashboard-content-form-container">
                          <label>Award/Certificate Title</label>
                          <br />
                          <input
                            type="text"
                            name="title"
                            value={award.title}
                            placeholder="Award/Certificate Title"
                            onChange={(event) => handleChange(event, index)}
                          />
                        </div>
                        <div className="dashboard-content-form-container">
                          <label>Awarder/Certifier</label>
                          <br />
                          <input
                            type="text"
                            name="awarder"
                            value={award.awarder}
                            placeholder="Awarder/Certifier"
                            onChange={(event) => handleChange(event, index)}
                          />
                        </div>
                        <div className="dashboard-content-form-container">
                          <label>Certificate Link</label>
                          <br />
                          <input
                            type="text"
                            name="certificate_link"
                            value={award.certificate_link}
                            placeholder="Enter link to view certificate"
                            onChange={(event) => handleChange(event, index)}
                          />
                        </div>
                        <div className="dashboard-content-form-container">
                          <label>Year Awarded</label>
                          <br />
                          <select
                            name="monthAwarded"
                            value={award.monthAwarded}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select Month Awarded</option>
                            <option value="Jan">January</option>
                            <option value="Feb">February</option>
                            <option value="Mar">March</option>
                            <option value="Apr">April</option>
                            <option value="May">May</option>
                            <option value="Jun">June</option>
                            <option value="Jul">July</option>
                            <option value="Aug">August</option>
                            <option value="Sep">September</option>
                            <option value="Oct">October</option>
                            <option value="Nov">November</option>
                            <option value="Dec">December</option>
                          </select>
                          <select
                            value={award.yearAwarded}
                            name="yearAwarded"
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select Year Awarded</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                          </select>
                        </div>
                        <div className="dashboard-content-form-actions">
                          {/* <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form1")} alt="delete"/>
                                        </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                );
              })}

              <div className="dashboard-content-form-actions">
                <img
                  src={addForm}
                  onClick={addNewForm}
                  alt="add"
                  className="dashboard-content-form-actions-add"
                />
              </div>

              <div className="dashboard-content-form-edu-submit">
                <button onClick={submitDynamicForm} id="save">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Awards;
