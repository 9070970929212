import axios from 'axios';


let APIKit = axios.create({
    baseURL: 'https://countriesnow.space/api/v0.1/countries',
    timeout: 10000, // timeout can be i5ncreased
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
});

let payload = JSON.stringify({
    country: "Nigeria"
})

export const getAllStates = () => {
    return APIKit.post(`/states`, payload)
}

export const getAllCities = () => {
    return APIKit.post(`/cities`, payload)
}