// import Logo from "../images/svg/grazac-icon.svg";
import loader_gif from "../images/gif/loader-purple.gif";

const Loader = () => {
  return (
    <div className="loading">
      <div className="loading_container">
        <div className="loading_box ">

        <img
          src={loader_gif}
          alt="loader_gif"
          style={{ width: "100px", margin: "50px auto", display: "block" }}
        />
          {/* <img src={Logo} className="lds-img" alt="ccc" /> */}
          <div className="lds-ring ">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
