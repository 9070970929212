import email from '../../images/svg/email.svg';

function MailSent () {
    return(
        <div>
            <img src={email} alt="email" className="auth_container_form-email" />
            <h3>Password reset link sent!</h3>
            <p className="auth_container_form-text">We have sent you an email containing a link to reset your password, kindly check your email to set a new password.</p>
        </div>
    )
}

export default MailSent;