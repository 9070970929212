import { useState } from "react";
import { addProjectData } from "../../../lib/hire";

import addForm from "../../../images/add-form.png";
import deleteForm from "../../../images/delete-form.png";
import loader from "../../../images/gif/loader.gif";

function Projects({ userId, next, user, fetched }) {
  const [projectDetails, setProjectDetails] = useState([
    {
      type: "Project",
      role: "",
      title: "",
      description: "",
      project_link: "none",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const [project1, setProject1] = useState(true);

  const handleChange = (event, index) => {
    // console.log(event.target.value);
    let data = [...projectDetails];
    if (event.target.value === "") {
      // can't be
      alert("Please fill the form to proceed");
    }

    data[index][event.target.name] = event.target.value;
    setProjectDetails(data);

    // console.log(inputDetails);
  };
  const addNewForm = () => {
    let newForm = {
      type: "Project",
      role: "",
      title: "",
      description: "",
      project_link: "none",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    };

    setProjectDetails([...projectDetails, newForm]);
  };

  const submitDynamicForm = () => {
    const payload = JSON.stringify({
      info: [projectDetails],
    });
    mainSubmitAction(payload);
    console.log(payload);
  };

  const mainSubmitAction = (payload) => {
    const btn = document.getElementById("save");
    console.log(userId);
    addProjectData(userId, payload)
      .then((res) => res)
      .then((resp) => {
        console.log(resp);
        next();
        btn.innerHTML = `Finish`;
      })
      .catch((err) => {
        console.log(err);
        btn.innerHTML = `Finish`;
      });
  };

  return (
    <div>
      <div className="dashboard-content-form-edu" id="formMain">
        <h3>Project & Experience</h3>
        <div>
          {fetched ? (
            <div>
              {user.project?.map((item, idx) => (
                <div style={{ padding: "0 40px 50px" }} key={idx}>
                  <div className="dashboard-content-form-container">
                    <h5>Project Title/ Company Name</h5>
                    <p>{item.title}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Role</h5>
                    <p>{item.role}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Description</h5>
                    <p>{item.description}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>Start Year</h5>
                    <p>{item.startYear}</p>
                  </div>
                  <hr />
                  <div className="dashboard-content-form-container">
                    <h5>End Year</h5>
                    <p>{item.endYear}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {projectDetails.map((input, index) => {
                return (
                  <form onSubmit={submitDynamicForm}>
                    <div key={index}>
                      <div className="dashboard-content-form-edu-add">
                        <div className="dashboard-content-form-container">
                          <label>Select Type</label>
                          <br />
                          <select
                            name="type"
                            value={input.type}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="Project">Project</option>
                            <option value="Experience">Experience</option>
                          </select>
                        </div>
                        <div className="dashboard-content-form-container">
                          <label>Role</label>
                          <br />
                          <input
                            type="text"
                            required
                            name="role"
                            value={input.role}
                            onChange={(event) => handleChange(event, index)}
                            placeholder="Enter your role"
                          />
                        </div>
                        <div className="dashboard-content-form-container">
                          {project1 && <label>Project Title</label>}
                          {!project1 && <label>Company name</label>} <br />
                          <input
                            type="text"
                            required
                            name="title"
                            value={input.title}
                            onChange={(event) => handleChange(event, index)}
                          />
                        </div>
                        <div className="dashboard-content-form-container">
                          {project1 && <label>Project Description</label>}
                          {!project1 && <label>Job Description</label>}
                          <br />
                          <textarea
                            type="text"
                            required
                            name="description"
                            value={input.description}
                            onChange={(event) => handleChange(event, index)}
                            placeholder="Enter description"
                          />
                        </div>
                        {project1 && (
                          <div className="dashboard-content-form-container">
                            <label>Project Link</label>
                            <br />
                            <input
                              type="text"
                              required
                              name="project_link"
                              value={input.project_link}
                              onChange={(event) => handleChange(event, index)}
                              placeholder="Enter a link to your project"
                            />
                          </div>
                        )}
                        <div className="dashboard-content-form-container">
                          <label>Start Year</label>
                          <br />
                          <select
                            name="startMonth"
                            value={input.startMonth}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select Start Month</option>
                            <option value="Jan">January</option>
                            <option value="Feb">February</option>
                            <option value="Mar">March</option>
                            <option value="Apr">April</option>
                            <option value="May">May</option>
                            <option value="Jun">June</option>
                            <option value="Jul">July</option>
                            <option value="Aug">August</option>
                            <option value="Sep">September</option>
                            <option value="Oct">October</option>
                            <option value="Nov">November</option>
                            <option value="Dec">December</option>
                          </select>
                          <select
                            name="startYear"
                            value={input.startYear}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select Start Year</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                          </select>
                        </div>
                        <div className="dashboard-content-form-container">
                          <label>End Year</label>
                          <br />
                          <select
                            name="endMonth"
                            value={input.endMonth}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select End Month</option>
                            <option value="Jan">January</option>
                            <option value="Feb">February</option>
                            <option value="Mar">March</option>
                            <option value="Apr">April</option>
                            <option value="May">May</option>
                            <option value="Jun">June</option>
                            <option value="Jul">July</option>
                            <option value="Aug">August</option>
                            <option value="Sep">September</option>
                            <option value="Oct">October</option>
                            <option value="Nov">November</option>
                            <option value="Dec">December</option>
                          </select>
                          <select
                            name="endYear"
                            value={input.endYear}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option value="">Select End Year</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                          </select>
                        </div>
                        <div className="dashboard-content-form-actions">
                          {/* <div className="dashboard-content-form-actions-delete">
                                            <img src={deleteForm} onClick={()=>deleteSomeForm("form1")} alt="delete"/>
                                        </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                );
              })}

              <div className="dashboard-content-form-actions">
                <img
                  src={addForm}
                  onClick={addNewForm}
                  alt="add"
                  className="dashboard-content-form-actions-add"
                />
              </div>
              <div className="dashboard-content-form-edu-submit">
                <button onClick={submitDynamicForm} id="save">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Projects;
